import React, { Component, useEffect, useState } from "react";
import _ from 'lodash';
import { accountService } from '../common/services/account';
import NotExist from '../common/components/not-exist';
import LoadingPanel from '../common/components/loading-panel';
import SmartContract from '../common/components/smart-contract';
import { withTranslation } from "react-i18next";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';



const NUM_TRANSACTIONS = 20;
const today = new Date().toISOString().split("T")[0];
let scrollTimes = 0;
let maxScrollTimes = 1;


class SmartContractDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: this.getEmptyAccount(this.props.params.accountAddress),
      loading_acct: false,
      loading_txns: false,
      loading_txns1: false,
     sk:null
    
      // rameSourceTxs: null,
    };
   }

  static defaultProps = {
    includeDetails: true,
    truncate: window.screen.width <= 560 ? 10 : 35,
  }
  getEmptyAccount(address) {
    return {
      address: address.toLowerCase(),
      balance: { pandowei: 0, ptxwei: 0 },
      sequence: 0,
      reserved_funds: [],
      txs_counter: {}
    }
  }
 


  componentDidUpdate(preProps, preState) {
  
    if (preProps.params.accountAddress !== this.props.params.accountAddress) {
      const query = new URLSearchParams(this.props.location.search);

      const token = query.get('sk')
      this.setState({ })
      this.setState({ account: { address: this.props.params.accountAddress },sk: token })
      this.fetchData(this.props.params.accountAddress);
    }
    
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { accountAddress } = this.props.params;
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get('sk')
    const platifrms = query.get('platform')
    this.setState({sk: token,plat:platifrms})
    this.fetchData(accountAddress);

  }
  fetchData(address) {
   
    this.getOneAccountByAddress(address);

  }


 
  getOneAccountByAddress(address) {
    if (!address) {
      return;
    }

    this.setState({ loading_acct: true });
    accountService.getOneAccountByAddress(address)
      .then(res => {
        switch (res.data.type) {
          case 'account':
            this.setState({
              account: res.data.body,
              errorType: null
            })
            break;
          case 'error_not_found':
            this.setState({
             
              errorType: 'invalid_address'
            })
            break;
          default:
            break;
        }
      
      }).catch(err => {
        this.setState({ loading_acct: false });
        console.log(err)
        this.setState({ errorType: err.response.data.type })
      })
  }


 
  calulateFee = (val) => {
    if (val.includes('.')) {
      let splitNum = val?.split('.');
      return `${splitNum[0]}.${splitNum[1]?.slice(0, 4)}`;
    } else {
      return val;
    }
  }

  handleHashScroll = () => {
    let tabName = this.props.location.hash.replace("#", "").split('-')[0];
    if (tabName && this.tabRef && scrollTimes < maxScrollTimes) {
      setTimeout(() => this.tabRef.scrollIntoView({ behavior: "smooth" }));
      scrollTimes++;
    }
  }

  render() {
    const { t, truncate } = this.props
    const { account,  errorType,loading_txns,tabIndex,sk,plat } = this.state;

    return (
      <div className="content account contract-verification">

        {errorType === 'invalid_address' &&
          < NotExist msg={t(`INVALID_ADDRESS`)} t={t} />}
       
        {
          !errorType &&
          <React.Fragment>
            {
              loading_txns ? <LoadingPanel className="fill" /> :
                <div className="fhg54-tb ">
                  <div className="juh6">
                    <div className="title text-center mb-4"><p className="ss5dsx">{t(`Smart Contract Verification`)} 
                    
                    </p></div></div>
                  <Tabs >
                    <TabList>
                      
                      {/* {account.code && account.code !== '0xc5d2460186f7233c927e7db2dcc703c0e500b653ca82273b7bfad8045d85a470' &&
                        <Tab onClick={() =>this.chnagepranerttab(1)}>{t(`SMART CONTRACT`)}</Tab>
                      } */}
                      </TabList>

                    {account.code &&
                      account.code !== '0xc5d2460186f7233c927e7db2dcc703c0e500b653ca82273b7bfad8045d85a470' &&
                      <TabPanel>
                        <SmartContract address={account.address} handleHashScroll={this.handleHashScroll} urlHash={location.hash}  sk={sk} plat={plat}/>
                      </TabPanel>
                    }
                   
                  </Tabs>
                </div>
            }</React.Fragment>

        }
      </div >
    );
  }
}


const calulateFee = (val) => {
  return val.toFixed(6)

}



export default withTranslation()(SmartContractDetails)
