import { BigNumber } from 'bignumber.js';
import PandoJS from './pandojs.esm';
import config from '../config';

export default class Pando {
  static _chainId = config.chainId;

  static get chainId() {
    return this._chainId;
  }

  static getTransactionFee() {
    return 0.1;
  }

  static unsignedSmartContractTx(txData, sequence) {
      
    let { from, to, data, value, transactionFee, gasLimit } = txData;
   
        
    const ten18 = (new BigNumber(10)).pow(18); // 10^18, 1  = 10^18 , 1 Gamma = 10^ 
    const feeInPTOWei = (new BigNumber(transactionFee)).multipliedBy(ten18); // Any fee >= 10^12  should work, higher fee yields higher priority
    const senderSequence = sequence;
    const gasPrice = feeInPTOWei;

    let tx = new PandoJS.SmartContractTx(from, to, gasLimit, gasPrice, data, value, senderSequence);

    return tx;
}
}