import React, { useState, useRef, useEffect } from "react";
import get from 'lodash/get';
import map from 'lodash/map';
import merge from 'lodash/merge';
import { ethers } from "ethers";
import smartContractApi from '../services/smart-contract-api';
import Pando from '../../libs/Pando';
import PandoJS from '../../libs/pandojs.esm'

import config from "../../config";
import Web3 from 'web3';

const rpcURL = config.rpcUrl
const web3 = new Web3(rpcURL);

let istruemvg = false;

export default function ReadContract(props) {

  const { abi, address, plat } = props;




  return (<div>
    {abi.map((data, i) => {
      return (<FunctionUnit key={i} functionData={data} index={i + 1} address={address} abi={abi} plat={plat}>{JSON.stringify(data)}</FunctionUnit>)
    })}
  </div>)
}

const FunctionUnit = (props) => {

  const { functionData, index, address, abi, plat } = props;

  const inputs = get(functionData, 'inputs');
  const outputs = get(functionData, 'outputs');
  const [callResult, setCallResult] = useState(null);
  const [inputValues, setInputValues] = useState(new Array(inputs.length));
  const decodedParameters = get(callResult, 'decodedParameters');
  const hasInput = inputs.length > 0 || false;
  const vm_error = get(callResult, 'vm_error');
  const dsds = [];
  async function fetchFunction() {

    const iface = new ethers.utils.Interface(abi || []);
    const senderSequence = 1;
    const functionInputs = get(functionData, ['inputs'], []);
    const functionOutputs = get(functionData, ['outputs'], []);
    const functionSignature = iface.getSighash(functionData.name)

    const inputTypes = map(functionInputs, ({ name, type }) => {
      return type;
    });
    try {
      var abiCoder = new ethers.utils.AbiCoder();
      var encodedParameters = abiCoder.encode(inputTypes, inputValues).slice(2);;
      const gasPrice = Pando.getTransactionFee(); //
      const gasLimit = 1000000;
      const data = functionSignature + encodedParameters;
      const tx = Pando.unsignedSmartContractTx({
        from: address,
        to: address,
        data: data,
        value: 0,
        transactionFee: gasPrice,
        gasLimit: gasLimit
      }, senderSequence);
      const rawTxBytes = PandoJS.TxSigner.serializeTx(tx);
      const callResponse = await smartContractApi.callSmartContract({ sctx_bytes: rawTxBytes.toString('hex').slice(2) });

      const callResponseJSON = callResponse.data;
      const result = get(callResponseJSON, 'result');

      let outputValues = get(result, 'vm_return');
      const outputTypes = map(functionOutputs, ({ name, type }) => {
        return type;
      });
      outputValues = /^0x/i.test(outputValues) ? outputValues : '0x' + outputValues;

      let finalas = abiCoder.decode(outputTypes, outputValues)

      setCallResult(merge(result, {
        outputs: functionOutputs,
        decodedParameters: finalas,

      }));



      setTimeout(() => {

        if (plat == 'mvg') {
          istruemvg = true
        }
        tosubsd()


      })

    }
    catch (e) {

      setCallResult({ vm_error: e.message })
    }
  }



  // async function fetchFunctionCertixpert(){
  //   let pandoContract = new web3.eth.Contract(abi, '0xcE8aa16a9eB5683500D8a9323Ce3C9A755f9CA42');
  //   const callResponse = await pandoContract.methods.certDetails().call();
  //   }



  const onBlur = (e, i) => {
    let val = e.target.value;
    let type = inputs[i].type;
    let newVals = inputValues.slice();
    newVals[i] = val;
    setInputValues(newVals);
  }

  const onSubmit = () => {
    fetchFunction();
  }

  function tosubsd() {

    try {
      let ets = $('.sjh8g').html()
      var all = $(".sjh8g").map(function () {
        let test = this.innerHTML.split(',');


        let str = ''
        for (let i of test) {

          str += `<span>${i}</span>`;
        }
        $(this).html(str)
        return str;
      }).get();


    }
    catch {

    }
  }

  function tosubsdss(data) {


    try {

      data = data.join(",")
      return data
    }
    catch {
      return data
    }

  }
  function readfun(data) {
    if (data.length > 0) {
      data = data.split(",");
      return data;
    }
    // 
    // }
    // catch {
    //   return data
    // }

  }



  useEffect(() => {
    if (inputs.length === 0) fetchFunction();
  }, [])
  return (<div className="read-contract__wrapper">

    <div className="read-contract__title">{`${index}. ${functionData.name}`}</div>

    <div className="read-contract__content">
      {hasInput &&
        <>
          <div className="read-contract__inputs">
            {inputs.map((input, i) =>

              <React.Fragment key={i}>
                <div className="read-contract__input" >
                  <label>{`${input.name}(${input.type}): `}</label>
                  <div style={{ flex: 1 }}>
                    <input type="text" placeholder={`${input.name}(${input.type})`} onBlur={e => onBlur(e, i)} id={`${functionData.name}${input.name}(${input.type})`} ></input>
                  </div>
                </div>
              </React.Fragment>)}
          </div>
          <div className="read-contract__input--row">
            <button className="read-contract__input--query" onClick={onSubmit} id={`${functionData.name}btn`}>Query</button>
            {vm_error && <div className="text-danger read-contract__input--error">Error: {vm_error}</div>}
          </div>
          <div className="read-contract__outputs-template">
            &#8627;&nbsp;
            {outputs.map((output, i) => <span key={i}>{(i == 0 ? '' : ', ') + output.name} <i>{output.type}</i></span>)}
            {/* {outputs.map((output, i) => { return ` ${output.name} ${output.type}` } */}
            {/* )} */}
          </div>
        </>}
      {decodedParameters && !hasInput &&
        <div className="read-contract__outputs">
          {outputs.map((output, i) =>
            <div className="read-contract__output" key={i}>
              <div className="read-contract__output--content">
                {plat != 'mvg' &&
                  <div className="sjh8g">
                    {tosubsdss(decodedParameters[i].toString())}
                  </div>
                }




              </div>
              {plat != 'mvg' &&
                <div className="read-contract__output--unit">{output.type}</div>}
            </div>)}

          {plat == 'mvg' &&
            <div className="sjh8g">
              {/* <div className="sjh8g sredwer3" >
                    {tosubsdss(decodedParameters[0].toString())}
                  </div> */}

              <div className="icnsicn">
                <span className="topsnebar">
                  <h5>UVT = USDT</h5>
                  <p>
                    {readfun(decodedParameters[0].toString())[0].split('=')[1]}
                  </p>
                </span>
                <span className="topsnebar">
                  <h5>Smart Contract Stake</h5>
                  <p>
                    {readfun(decodedParameters[0].toString())[1].split('=')[1]}
                  </p>
                </span>
                <span className="topsnebar">
                  <h5>Smart Contract Fee</h5>
                  <p>
                    {readfun(decodedParameters[0].toString())[2].split('=')[1]}
                  </p>
                </span>
                <span className="topsnebar">
                  <h5>1 Day</h5>
                  <p>
                    {readfun(decodedParameters[0].toString())[8].split('=')[1]}
                  </p>
                </span>
                <span className="topsnebar">
                  <h5>Timestamp</h5>
                  <p>
                    {readfun(decodedParameters[0].toString())[9].split('=')[1]}
                  </p>
                </span>
              </div>

              <div className="sedondf">
                <div className="chandand3rxs">
                  <span className="chandleft">Smart Contract Date</span>
                  <span className="chandright">
                    {readfun(decodedParameters[0].toString())[10].split('=')[1].replace("+0000 (Coordinated Universal Time)", "")}
                  </span>
                </div>

                <div className="chandand3rxs">
                  <span className="chandleft">Username</span>
                  <span className="chandright">
                    {readfun(decodedParameters[0].toString())[12].split('=')[1]}
                  </span>
                </div>

                <div className="chandand3rxs">
                  <span className="chandleft">Smart Contract Expiry</span>
                  <span className="chandright">
                    {readfun(decodedParameters[0].toString())[11].split('=')[1].replace("+0000 (Coordinated Universal Time)", "")}
                  </span>
                </div>

                <div className="chandand3rxs">
                  <span className="chandleft">Stake Id</span>
                  <span className="chandright">
                    {readfun(decodedParameters[0].toString())[13].split('=')[1]}
                  </span>
                </div>


              </div>
              <div className="chanthirdsec">
                <div className="fsl">
                  <span className="fslfts">Stake Start</span>
                  <span className="fslfss">0 Days</span>
                  <span className="fslfst">150UVT</span>
                </div>

                <div className="fsl">
                  <span className="fslfts">Stake Return</span>
                  <span className="fslfss">100 Days</span>
                  <span className="fslfst">150UVT</span>
                </div>


                <div className="fsl">
                  <span className="fslfts">Airdrop</span>
                  <span className="fslfss">120 Days</span>
                  <span className="fslfst">150UVT</span>
                  <p>
                    Qualification
                  </p>
                  <p>
                    L1= 3 Active KYC
                  </p>
                  <p>
                    L2 = 6 active KYC
                  </p>
                </div>


                <div className="fsl fsf">
                  <span className="fslfts">Staking Rewards</span>
                  <span className="fslfss">140 Days</span>
                  <span className="fslfst">75UVT</span>

                </div>

              </div>


              <div className="thirsread">
                <div class="thirdfs">
                  <h5>Stake Return</h5>
                  <p>USD 150 (stake amount will be returned on the 100th day)</p>
                  <p>All Smart Contract owners will receive Stake Return.</p>
                </div>
                <div class="thirdfs">
                  <h5>Airdrop</h5>
                  <p>USD 75 (will be calculated and distributed on the 120th day)</p>
                  <p>Airdrop will ONLY be distributed to qualified members.</p>
                </div>
                <div class="thirdfs">
                  <h5>Staking Rewards</h5>
                  <p>USD 75 (upon completion of 140 days)</p>
                  <p>All Smart Contract owners will receive Staking Reward.
                  <br/>
                  Members can only purchase a maximum of 10 Smart Contract per person.
                </p>
              </div>
            </div>

            </div>
      }
    </div>}
    {decodedParameters && hasInput &&
      <div className="read-contract__outputs">
        <div className="read-contract__output--response">[ <b>{functionData.name}</b> method Response ]</div>
        {outputs.map((output, i) =>
          <div className="read-contract__output" key={i}>
            <div className="read-contract__output--unit">
              <span className="text-green">&#8658;</span>
              {` Type: ${output.name} ${output.type}`}
              <br />


              {plat != 'mvg' &&
                <div className="sjh8g">
                  {tosubsdss(decodedParameters[i].toString())}
                </div>
              }


              {plat == 'mvg' &&
                <div className="sjh8g">
                  {/* <div className="sjh8g sredwer3" >
                    {tosubsdss(decodedParameters[0].toString())}
                  </div> */}

                  <div class="icnsicn">
                    <span>
                      <h5>UVT = USDT</h5>
                      <p>
                        {readfun(decodedParameters[0].toString())}
                      </p>
                    </span>
                    <span>
                      <h5>Smart Contract Stake</h5>
                      <p></p>
                    </span>
                    <span>
                      <h5>Smart Contract Fee</h5>
                      <p></p>
                    </span>
                    <span>
                      <h5>1 Day</h5>
                      <p></p>
                    </span>
                    <span>
                      <h5>Timestamp</h5>
                      <p></p>
                    </span>
                  </div>

                </div>
              }



            </div>
          </div>)}


      </div>}
  </div>


  </div >)
}