import React, { Component } from "react";
import Header from './common/components/header';
import { browserHistory, Link } from "react-router";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLink: true,
    };
  }
  render() {

    setTimeout(() => {
      this.setState({ activeLink: false });

    }, 2000)


    return (

      <div id="app-container" className="body-color" >
        <div className={
          this.state.activeLink
            ? " global-spinner "
            : "global-spinner active"
        }  >
          <img src="./../images/Comp 12.gif" />
        </div>
        <Header />
        <div id="app-content">
          {this.props.children}
        </div> 
        <div class="footer" id="colophon">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
             <div className="feera">
             <img src="./../images/pando explorer logo 1.png" />
             <p>PandoExplorer is a Block Explorer and Analytics Platform for Pandoproject Network Chain</p>
             <div className="linkss">
              <a href="https://www.facebook.com/pandoprojectorg" target="_blank"><img src="./../images/new-design/Group 85.svg" /></a>
              <a href="https://twitter.com/pandoprojectorg" target="_blank" className="twit"><img src="./../images/new-design/Group 86.svg" /></a>
              <a href="https://www.instagram.com/pandoprojectorg/?hl=en" target="_blank"><img src="./../images/new-design/Group 88.svg" /></a>
              <a href="https://discord.com/channels/896782993123860540/1041980349707714600" target="_blank"><img src="./../images/new-design/Discord.svg" /></a>
               <a href="https://t.me/pandoproject" target="_blank"><img src="./../images/new-design/Telegram.svg" /></a>
             </div>
             <></>
             </div>
            </div>
            <div className="col-lg-2 col-sm-6 col-xs-3">
              <div className="footer-link">
                <h4>Browse</h4>
                <ul>
                  <li><Link to="/txs" className="" >Transactions  </Link></li>
                  <li><Link to="/blocks" className="" >Blocks  </Link></li>
                  <li><Link to="/stakes" className="" >Stakes  </Link></li>
                  <li><a href="https://wallet.pandoproject.org/" target="_blank">Web wallet  </a></li>
                  <li></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6 col-xs-3">
            <div className="footer-link">
                <h4>Mainnet 3.0</h4>
                <ul>
                  <li><a href="https://pandoproject.org/rametron/" target="_blank">Rametron</a></li>
                  <li><a href="https://pandoproject.org/validator/" target="_blank">Validator Node</a></li>
                  <li><a href="https://pandoproject.org/ptx/" target="_blank">PTX</a></li>
                  <li><a href="https://pandoproject.org/nft/" target="_blank">NFT</a></li>
                  <li><a href="https://pandoproject.org/mainnet-3-0/" target="_blank">Mainnet 3.0</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6 col-xs-3">
            <div className="footer-link">
                <h4>About us</h4>
                <ul>
                  <li><a href="https://pandoproject.org/terms-of-service/" target="_blank">Terms of Services</a></li>
                  <li><a href="https://pandoproject.org/privacy-policy/" target="_blank">Privacy Policy</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-xs-3">
            <div className="footer-link">
                <h4>Contact us</h4>
                <p>Contact us via email for assistance.</p>
                <div className="fomm">
                <form><div class="input-field"><a href="mailto:support@pandolab.io">support@pandolab.io</a></div></form>
                {/* <form>
                  <div class="form-group submitt">
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
                    <button type="submit" class=""><img src="./../images/new-design/Frame 32.svg" /></button>
                  </div>
                </form> */}
                </div>
              </div>
            </div>
          </div>
          <div className="cpyy">
            <p>© 2023 <a href="https://pandoproject.org/privacy-policy/" target="_blank">PandoProject</a> | Powered by <a href="https://pandolab.io/">Pandolab</a></p>
          </div>
        </div>
      </div>
    );
  }
}