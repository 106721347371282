// const BASE_URL = "https://api-wallet.pandotoken.org";
import config from "../../config";
import axios from 'axios';

const BASE_URL = config.BASE_URL;

const DEFAULT_HEADERS = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
};

//
//Helpers
//

export function isResponseSuccessful(response){
    let { status } = response;

    return (status === 200 || status === 201 || status === 202 || status === 204);
}

function objectToQueryString(object) {
    if(!object){
        return "";
    }

    let queryString = Object.keys(object).map(function(key) {
        let val = object[key];
        if(val){
            return encodeURIComponent(key) + '=' + encodeURIComponent(object[key]);
        }
    }).join('&');

    if(queryString.length > 0){
        return "?" + queryString;
    }
    else{
        return "";
    }
}

//
// Builder
//
function buildHeaders(additionalHeaders) {
  //TODO inject auth headers here...
  return Object.assign(DEFAULT_HEADERS, additionalHeaders);
}
// function buildURL(path, queryParams) {
//   let url = null;

//   if (path.startsWith("http://") || path.startsWith("https://")) {
//     url = path + objectToQueryString(queryParams);
//   }
//   else {
//     url = BASE_URL + objectToQueryString(queryParams);
//   }

//   return url;
// }

function sendRequest(path, method, body) {
  let url = path
 // let headers = buildHeaders(additionalHeaders);

  let opts = {
    method: method,
    body: body
    // headers: headers,
  };

  // if (body) {
    // opts['body'] = JSON.stringify(body);
  // }

  return fetch(url, opts);
}
//
//Convenience requests
//
function POST(path, body) {
  return sendRequest(path, "POST", body);
}

export default class Api {
  static callSmartContract(body) {
    
    //let path = "/smart-contract/call";
    
       let finalbody = {
          "jsonrpc": "2.0",
          "method": "pando.CallSmartContract",
          "params": body,
          "id": 1
      }
 

    return axios.post(BASE_URL+'/node', finalbody).then((data) => data.data);
  
  }
}


