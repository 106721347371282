import React, { Component } from "react";
import { browserHistory, Link } from 'react-router';
import _ from 'lodash';
import cx from 'classnames';
import { formatCoin, priceCoin } from '../../common/helpers/utils';
import { from, to, fee, value, hash, age, date, type, coins, purpose } from '../../common/helpers/transactions';
import { TxnTypeText, TxnClasses } from '../../common/constants';



export default class TransactionTable1 extends Component {
    constructor(props) {
        super(props);
        this.state = {

            transactions: [],
            account: null
        };

    }
    static defaultProps = {
        includeDetails: true,
        truncate: 20,
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.transactions && nextProps.transactions.length && nextProps.transactions !== prevState.transactions) {
            return { transactions: nextProps.transactions, account: nextProps.account };
        }
        return prevState;
    }
    componentDidMount() {

    }
    componentWillUnmount() {

    }
    // 

    handleRowClick = (hash) => {
        browserHistory.push(`/txs/${hash}`);
    }

    render() {
        const { className, includeDetails, truncate, account, t } = this.props;
        const { transactions } = this.state;
        const address = account ? account.address : null;
        return (
            <div className="table-responsive">
                <table className={cx("data txn-table2 ", className)}>

                    <tbody>
                        {_.map(transactions, txn => {
                            let source = null;
                            if (txn.eth_tx_hash != '0x0000000000000000000000000000000000000000000000000000000000000000' && txn.data) {
                                txn.verified = "verified"
                            }
                            source = !account ? 'none' : account.address === from(txn, null, account) ? 'from' : 'to';
                            return (
                                <tr key={txn.hash} className={TxnClasses[txn.type] + ' type'}>

                                    <td className="hash overflow hasgfr4">
                                        <div className="flx">
                                            <div className="blhhy">
                                                <img src="./images/new-design/Mask group1.png" />
                                            </div>
                                            <div className="lzi8">
                                                <Link to={`/txs/${txn.hash}`}>{hash(txn, truncate)}</Link><p>{age(txn)}</p>
                                            </div>
                                        </div>

                                    </td>
                                    {includeDetails &&
                                        <React.Fragment>
                                            <td className={cx({ 'dim': source === 'to' }, "from overflow")}>
                                                <div className="flx"><span className="flxfrom">From </span> <Link to={`/account/${from(txn)}?tx=transfer`}>{from(txn, 20)}</Link></div></td>
                                           
                                        </React.Fragment>}
                                    <td className={type(txn) + ' type '} ><p>{type(txn)} {purpose(txn?.data?.purpose)} {txn.verified == "verified" &&
                                        (<div className="ethverified">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                            </svg>
                                            <span>ETH Verified</span>
                                        </div>
                                        )}</p>
                                    </td>
                                </tr>);
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

const Value = ({ coins, price }) => {
    let newObj = Object.fromEntries(
        Object.entries(coins).map(([k, v]) => [k.toLowerCase(), v])
    );
    const isMobile = window.screen.width <= 560;
    return (
        <React.Fragment>
            <div className="currency pando">
                {/* {formatCoin(coins.PandoWei)} */}
                {!isMobile && "Pando"}
                {!isMobile && <div className='price'>{`[\$${priceCoin(newObj.ptxwei, price['PTX'])} USD]`}</div>}
            </div>
            {/* <div className="currency PTX">
        {formatCoin(coins.PTXWei)}
       
       
      </div> */}
        </React.Fragment>)
}