import { apiService } from './api';
import { CryptoService } from '../services/crypto.service'
import * as  moment from 'moment'
import axios from 'axios'

export const stakeService = {
  getAllStake() {
    return apiService.get(`stake/all`, {});
  },
  getTotalStake() {
    return apiService.get(`stake/totalAmount`, {})
  },
  getStakeByAddress(address) {
    if (!address) {
      throw Error('Missing argument');
    }
    return apiService.get(`stake/${address}`, {});
  },

  overallBalance(address) {
    if (!address) {
      throw Error('Missing argument');
    }
    return apiService.get(`account/update/${address}`, {}).then((data) => data.data);
  },




  getRametronData(pageNo = 1, pageSize = 20) {
    return apiService.get(`stake/allRametron`).then((data) => data.data)
  },



  rametronTotalStake() {
    return apiService.get(`stake/totalRametron`, {}).then((data) => data.data);
  },

  allZyta() {
    return apiService.get(`stake/allZyta`, {}).then((data) => data.data);
  },
  allMeta() {
    return apiService.get(`stake/allMeta`, {}).then((data) => data.data);;
  },
  allTotal() {
    return apiService.get(`stake/allTotal`, {}).then((data) => data.data);;
  },




  getNodeStake(ip) {
    let body = {
      ip: ip
    }
    return apiService.post(`stake/node-status`, body).then((data) => data.data)
  },
  lbank() {
    return apiService.get(`lbank/price`).then((data) => data.data)
  },
  marketCap() {
    return apiService.get(`marketCap/price`).then((data) => data.data)
  },
  circulatingSupply() {
    return apiService.get(`circulating/supply`).then((data) => data.data)
  },
  totalWalletCount() {
    return apiService.get(`totalwallet`).then((data) => data.data)
  },
  totalWalletSupply() {
    return apiService.get(`sum_totalPTXwei`).then((data) => data.data)
  }
  ,
  totalcountryCount() {
    return apiService.get(`countryCodes`).then((data) => data.data)
  }
  ,
  totalcountryWalletCount() {
    return apiService.get(`totalWallet/country`).then((data) => data.data)
  }

  ,
  totalZytaCount() {
    return apiService.get(`get_total_zytatron`).then((data) => data.data)
  }
  ,
  totalMetaCount() {
    return apiService.get(`get_total_metatron`).then((data) => data.data)
  }
  ,
  totalRametronCount() {
    return apiService.get(`All_rametron_total_stake`).then((data) => data.data)
  }, totalMetaHolder() {
    return apiService.get(`get_metatron_holder`).then((data) => data.data)
  },
  totalZytaCHolder() {
    return apiService.get(`get_zytatron_holder`).then((data) => data.data)
  },
  stakerbyAddress(address) {
    return apiService.get(`total_stake&wallet/by/address?address=` + address).then((data) => data.data)
  },
  checkNodeStatus(address) {
    return apiService.get(`peerAddressTimestamp?peerAddress=` + address).then((data) => data.data)
  },

  getCountrywiseCurrency() {
    return apiService.get(`get_country_Currency_data`).then((data) => data.data)
  },
  getTotalFees() {
    return apiService.get(`getTotalFees`).then((data) => data.data)
  },
  getTotal_count() {
    return apiService.get(`getTotal_count`).then((data) => data.data)
  }

};

