import React, { Component } from "react";
import { stakeService } from '../common/services/stake';
import { browserHistory, Link } from 'react-router';
import PandoChart from '../common/components/chart';
import { formatNumber, formatCurrency, sumCoin } from '../common/helpers/utils';
import StakesTable from "../common/components/stakes-table";
import LoadingPanel from '../common/components/loading-panel';
import BigNumber from 'bignumber.js';
import { withTranslation } from "react-i18next";
import cx from 'classnames';

class Blocks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stakes: [],
      totalStaked: 0,
      holders: [],
      percentage: [],
      sortedStakesByHolder: [],
      sortedStakesBySource: [],
      rametron: {},
      loading: true,
      metatronStake: 0,
      zetatronStake: 0,
      rametronStake: 0,
      zytacount: 0,
      metacount: 0,
      remetrontotalWallets: 0,
      remetronEnterprisetotalWallets: 0,
      remetronEnterprisetotalStake: 0,
      remetronPrototalWallets: 0,
      remetronPrototalStake: 0,
      remetronLiteotalWallets: 0,
      remetronLitetotalStake: 0,
      remetronMobiletotalWallets: 0,
      remetronMobiletotalStake: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getAllStakes();
    this.getZytatronCount();
    this.getMetatronCount();
    this.getRametronCount();
  }


  // {"success":true,"message":"data is successfully get","data":[{"_id":"63ce92e89f68dd81afe25fba","data":[{"rtLite":[{"result":[{"_id":null,"totalStake":"17100000000000000000000"}],"totalWallet":16}],"RTpro":[{"result":[{"_id":null,"totalStake":"10000000000000000000000"}],"totalWallet":1}],"RTenterprise":[{"result":[{"_id":null,"totalStake":"0"}],"totalWallet":2}],"RTmobile":[{"result":[{"_id":null,"totalStake":"2000000000000000000000"}],"totalWallet":2}]}],"createAT":"2023-01-23T13:56:54.804Z","__v":0}],"status":200}
  getRametronCount() {
    this.setState({ loading: true });
    stakeService.totalRametronCount()
      .then(res => {
        res = res.data[0]

        let remetrontotalWallets = res.rtEnterprise.totalWallet + res.rtLite.totalWallet + res.rtMobile.totalWallet + res.rtPro.totalWallet;
        this.setState({
          remetrontotalWallets: remetrontotalWallets,
          remetronEnterprisetotalWallets: res.rtEnterprise.totalWallet,
          remetronPrototalWallets: res.rtPro.totalWallet,
          remetronLiteotalWallets: res.rtLite.totalWallet,
          remetronMobiletotalWallets: res.rtMobile.totalWallet,
          remetronEnterprisetotalStake: res.rtEnterprise.result[0].totalStake,
          remetronPrototalStake: res.rtPro.result[0].totalStake,
          remetronLitetotalStake: res.rtLite.result[0].totalStake,
          remetronMobiletotalStake: res.rtMobile.result[0].totalStake
        });
        this.setState({ loading: false });
        // console.log(res, remetrontotalWallets,res.RTenterprice.totalWallet,res.RTenterprice.result[0].totalStake)
      })
  }
  getZytatronCount() {
    // this.setState({ loading: true });
    stakeService.totalZytaCount()
      .then(res => {

        this.setState({ zytacount: res.totalwallet.response2[0].source });
      })
  }

  getMetatronCount() {
    // this.setState({ loading: true });
    stakeService.totalMetaCount()
      .then(res => {

        this.setState({ metacount: res.totalwallet.response2[0].source });
      })
  }


  getAllStakes() {
    this.setState({ loading: true });
    stakeService.getAllStake()
      .then(res => {

        let metatronStakeTotal = 0;
        let zetatronStakeTotal = 0;
        let rametronStakeTotal = 0;

        let stakeLists = res.data.body
        let metatronStake = stakeLists.filter((val) => val.type === 'gcp')
        let zetatronStake = stakeLists.filter((val) => val.type === 'vcp')
        let rametronStake = stakeLists.filter((val) => val.type === 'rametronenterprisep')
        metatronStake.map((val) => {
          metatronStakeTotal = Number(metatronStakeTotal + Number(val.amount))
        })
        zetatronStake.map((val) => {
          zetatronStakeTotal = Number(zetatronStakeTotal + Number(val.amount))
        })
        rametronStake.map((val) => {
          rametronStakeTotal = Number(rametronStakeTotal + Number(val.amount))
        })
        this.setState({
          metatronStake: metatronStakeTotal
        })
        this.setState({
          zetatronStake: zetatronStakeTotal
        })
        this.setState({
          rametronStake: rametronStakeTotal
        })



        let insit = []
        for (let i of res.data.body) {
          if (!(!!i.currentTime) || i.currentTime == 'dsdsds') {
            let temp = { type: i.type, holder: i.holder, source: i.source, amount: i.amount, withdrawn: i.withdrawn, return_height: i.return_height }

            insit.push(temp)
          }
        }

        const stakeList = insit

        let sum = stakeList.reduce((sum, info) => { return sumCoin(sum, info.amount) }, 0);
        let holderObj = stakeList.reduce((map, obj) => {
          if (!map[obj.holder]) {
            map[obj.holder] = {
              type: obj.type,
              amount: 0
            };
          }
          map[obj.holder].amount = sumCoin(map[obj.holder].amount, obj.amount).toFixed()
          return map;
        }, {});
        let sourceObj = stakeList.reduce((map, obj) => {
          if (!map[obj.source]) {
            map[obj.source] = {
              amount: 0
            };
          }
          map[obj.source].amount = sumCoin(map[obj.source].amount, obj.amount).toFixed()
          return map;
        }, {});
        let sortedStakesByHolder = Array.from(Object.keys(holderObj), key => {
          return { 'holder': key, 'amount': holderObj[key].amount, 'type': holderObj[key].type }
        }).sort((a, b) => {
          return b.amount - a.amount
        })
        let sortedStakesBySource = Array.from(Object.keys(sourceObj), key => {
          return { 'source': key, 'amount': sourceObj[key].amount }
        }).sort((a, b) => {
          return b.amount - a.amount
        })
        let sumPercent = 0;
        let topList = sortedStakesByHolder.slice(0, 8).map(stake => {
          let obj = {};
          obj.holder = stake.holder;
          obj.percentage = new BigNumber(stake.amount).dividedBy(sum / 100).toFixed(2);
          sumPercent += obj.percentage - '0';
          return obj;
        }).concat({ holder: 'Rest Nodes', 'percentage': (100 - sumPercent).toFixed(2) })
        this.setState({
          stakes: stakeList,
          totalStaked: sum,
          holders: topList.map(obj => { return obj.holder }),
          percentage: topList.map(obj => { return (obj.percentage - '0') }),
          sortedStakesByHolder: sortedStakesByHolder,
          sortedStakesBySource: sortedStakesBySource,
          // loading: false
        });

      })
      .catch(err => {

      });
  }

  render() {
    const { t } = this.props
    const { holders, percentage, sortedStakesByHolder, sortedStakesBySource, totalStaked, metatronStake, zetatronStake, rametronStake, zytacount, metacount, remetrontotalWallets,
      remetronEnterprisetotalWallets,
      remetronEnterprisetotalStake,
      remetronPrototalWallets,
      remetronPrototalStake,
      remetronLiteotalWallets,
      remetronLitetotalStake,
      remetronMobiletotalWallets,
      remetronMobiletotalStake } = this.state;
    let isTablet = window.screen.width <= 768;
    const truncate = isTablet ? 10 : 20;
    return (
      <div className="content stakes">

        {this.state.loading ?
          <LoadingPanel />
          :
          <>
            <div className="whsts stakess1 top-pad-60">
              <h1>Stakes</h1>
            </div>

            {/* new design section */}

            <div className="stak23">
              <div class="demgraph newdemo ">
                <div className="token dashboard newdashh PTX">
                  <div className="row fornew3">
                    <div className="col-lg-3">
                      <div className="column">
                        <div className="kiimcm">
                          <div className="side-icon"><img src="./images/new-design/stakes/Group 1000001007.svg" /></div>
                          <Detail title={`${t('RAMETRON STAKE')}`} value={formatNumber(rametronStake / 1000000000000000000)} />
                        </div>
                        <div className="kiimcm">
                          <div className="side-icon"><img src="./images/new-design/stakes/Group 1000001008.svg" /></div>
                          <Detail title={`${t('METATRON STAKE')}`} value={formatNumber(metatronStake / 1000000000000000000)} />
                        </div>
                        <div className="kiimcm">
                          <div className="side-icon"><img src="./images/new-design/stakes/Group (1).svg" /></div>
                          <Detail title={`${t('ZYTATRON STAKE')}`} value={formatNumber(zetatronStake / 1000000000000000000)} />
                        </div>

                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="">
                        <div className="stak-chart">
                          <div className="page-title stakes">{t(`TOTAL_STAKED`)}
                            <PandoChart chartType={'doughnut'} labels={holders} data={percentage} clickType={'account'} />
                            PTX Network Nodes

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="column">
                        <div className="kiimcm">
                          <div className="side-icon"><img src="./images/new-design/stakes/Group 1000001023.svg" /></div>
                          <Detail title={`${t(`RT Lite Stake`)}`} value={formatNumber(remetronLitetotalStake / 1000000000000000000)} />
                        </div>
                        <div className="kiimcm">
                          <div className="side-icon"><img src="./images/new-design/stakes/Group 1000001022.svg" /></div>
                          <Detail title={`${t('RT Mobile Stake')}`} value={formatNumber(remetronMobiletotalStake / 1000000000000000000)} />
                        </div>
                        <div className="kiimcm">
                          <div className="side-icon"><img src="./images/new-design/stakes/Group 1000001020.svg" /></div>
                          <Detail title={`${t(`RT Mobile Wallet`)}`} value={formatNumber(remetronMobiletotalWallets)} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row fornew3">
                    <div className="col-lg-3">
                      <div className="column">
                        <div className="kiimcm">
                          <div className="side-icon"><img src="./images/new-design/stakes/Group 1000001015.svg" /></div>
                          <Detail title={`${t(`RT Enterprise Wallet`)}`} value={formatNumber(remetronEnterprisetotalWallets)} />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="column">
                        <div className="kiimcm">
                          <div className="side-icon"><img src="./images/new-design/stakes/Group 1000001009.svg" /></div>
                          <Detail title={`${t('ZYTATRON wallets')}`} value={formatNumber(zytacount)} />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="column">
                        <div className="kiimcm">
                          <div className="side-icon"><img src="./images/new-design/stakes/Group 1000001069.svg" /></div>
                          <Detail title={`${t('Total Stake')}`} value={formatNumber((rametronStake + metatronStake + zetatronStake) / 1000000000000000000)} />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="column">
                        <div className="kiimcm">
                          <div className="side-icon"><img src="./images/new-design/stakes/Group 1000001013.svg" /></div>
                          <Detail title={`${t(`Rametron Wallets`)}`} value={formatNumber(remetrontotalWallets)} />
                        </div>

                      </div>
                    </div>
                  </div>

                  <div id="collapse-1" >
                    <div className="row fornew3">
                      <div className="col-lg-3">
                        <div className="column">
                          <div className="kiimcm">
                            <div className="side-icon"><img src="./images/new-design/stakes/Group 1000001019.svg" /></div>
                            <Detail title={`${t('RT Enterprise Stake')}`} value={formatNumber(remetronEnterprisetotalStake / 1000000000000000000)} />
                          </div>

                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="column">
                          <div className="kiimcm">
                            <div className="side-icon"><img src="./images/new-design/stakes/Group 1000001014.svg" /></div>
                            <Detail title={`${t(`RT Pro Wallet`)}`} value={formatNumber(remetronPrototalWallets)} />
                          </div>

                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="column">
                          <div className="kiimcm">
                            <div className="side-icon"><img src="./images/new-design/stakes/Group 1000001025.svg" /></div>
                            <Detail title={`${t(`RT Lite Wallet`)}`} value={formatNumber(remetronLiteotalWallets)} />
                          </div>

                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="column">
                          <div className="kiimcm">
                            <div className="side-icon"><img src="./images/new-design/stakes/Group 1000001011.svg" /></div>
                            <Detail title={`${t('Metatron wallet')}`} value={formatNumber(metacount)} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row fornew3">
                      <div className="col-lg-3">
                        <div className="column">
                          <div className="kiimcm">
                            <div className="side-icon"><img src="./images/new-design/stakes/Vector.svg" /></div>
                            <Detail title={`${t(`RT Pro Stake`)}`} value={formatNumber(remetronPrototalStake / 1000000000000000000)} />
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>




              </div>


            </div>






            <div className="table-container">
              <StakesTable type='wallet' stakes={sortedStakesBySource} totalStaked={totalStaked} truncate={truncate} rametron={this.state.rametron} />
            </div>

            <div className="f909 top-60 text-left">
              <h4 className="br-rel">Browse related searches</h4>

              <div className="browss ">

                <div className="fdr4">
                  <div className="de1">
                    <a href="https://pandoproject.org/mainnet-3-0/" target="_blank"><button className="mant3">Mainnet 3.0</button></a>
                    <a href=""><Link to="/txs" className="" ><button>Transactions</button></Link></a>
                  </div>
                  <div className="de1">
                    <a href=""><Link to="/nodes" className="" ><button>Top Nodes</button></Link></a>
                    <a href=""><Link to="/stakes" className="" ><button>Stakes</button></Link></a>
                  </div>
                  <div className="de2"></div>
                </div>
              </div>
            </div>
          </>
        }

      </div>
    );
  }
}

const Detail = ({ title, value }) => {
  return (
    <div className="detail">
      <div className="title">{title}</div>
      <div className={cx("value", { price: title.includes('Price') })}>{value}</div>
    </div>
  );
}


export default withTranslation()(Blocks)