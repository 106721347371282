import React, { Component } from "react";
import { browserHistory, Link } from 'react-router';
import { transactionsService } from '../common/services/transaction';
import Pagination from "../common/components/pagination";
import TransactionTable from "../common/components/transactions-table";
import LoadingPanel from '../common/components/loading-panel';
import { withTranslation } from "react-i18next";


const NUM_TRANSACTIONS = 20;
let interval;
class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: [],
      currentPage: 1,
      totalPages: 0,
      loading: false,
      price: { 'Pando': 0, 'PTX': 0 }
    };


  }
  componentWillUnmount() {
   clearInterval(interval)
  }
 
  componentDidMount() {
    window.scrollTo(0, 0);
    const { currentPage } = this.state;
    this.setState({ loading: true });
    this.fetchData(currentPage);
   
    
  }
  tesdt(currentPage) {
    
    this.setState({ currentPage: currentPage })
    transactionsService.getTransactionsByPage(currentPage, NUM_TRANSACTIONS)
      .then(res => {
        this.setState({ loading: false });
        if (res.data.type == 'transaction_list') {
          this.setState({
            transactions: _.orderBy(res.data.body, 'number', 'desc'),
            currentPage: _.toNumber(res.data.currentPageNumber),
            totalPages: _.toNumber(res.data.totalPageNumber),
            loading: false,
          })
        }
       
      })
      .catch(err => {
        this.setState({ loading: false });

      })
  }
  fetchData(currentPage) {
    this.setState({ loading: true });
    this.tesdt(currentPage);
    interval = setInterval(()=>{
      this.tesdt(this.state.currentPage);
    },6000)
  }
  

  handlePageChange = (pageNumber) => {
    window.scrollTo(0, 0);
    this.setState({ currentPage: pageNumber })
    this.fetchData(pageNumber);
  }

  handleRowClick = (hash) => {
    browserHistory.push(`/txs/${hash}`);
  }

  render() {
    const { t } = this.props
    const { transactions, currentPage, totalPages, loading, price } = this.state;
    return (
      <div className="content transactions">
        {/* <div className="page-title transactions" > <img src="./images/Group 503.svg" alt=""  /> {t(`TRANSACTIONS`)}  */}
        {/* <button className="btn custom-btn" onClick={() => this.fetchData(this.state.currentPage)} title="Refresh" ><img src="/images/Layer 2.svg" alt="" />
        </button> */}
        {/* </div> */}
        <div className="whsts transs top-pad-60">
                <h1>Transactions</h1>
          </div>
        {this.state.loading ?

          <LoadingPanel />
          :
          <>
            <TransactionTable transactions={transactions} price={price} t={t} />
            <Pagination
              size={'lg'}
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
              disabled={loading} />
          </>
        }

<div className="f909 top-60">
        <h4 className="br-rel">Browse related searches</h4>
        
        <div className="browss ">
          
        <div className="fdr4">
            <div className="de1">
              <a href="https://pandoproject.org/mainnet-3-0/" target="_blank"><button className="mant3">Mainnet 3.0</button></a>
              <a href=""><Link to="/txs" className="" ><button>Transactions</button></Link></a>
            </div>
            <div className="de1">
              <a href=""><Link to="/nodes" className="" ><button>Top Nodes</button></Link></a>
              <a href=""><Link to="/stakes" className="" ><button>Stakes</button></Link></a>
            </div>
            <div className="de2"></div>
          </div>
        </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(Transactions)