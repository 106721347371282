import React, { Component } from "react";
import { browserHistory, Link } from "react-router";
import { withTranslation } from "react-i18next";
import "../../index.css";
import { lang, Languages } from "../constants";
import config from "../../config";
import { stakeService } from "../services/stake";

class Header extends Component {

  constructor(props) {
    let activelink = "";
    if (window.location.pathname.includes("block")) {
      activelink = "block";
    } else if (window.location.pathname.includes("txs")) {
      activelink = "txs";
    } else if (window.location.pathname.includes("stakes")) {
      activelink = "stakes";
    } else if (window.location.pathname.includes("nodes")) {
      activelink = "nodes";
    } else if (window.location.pathname.includes("more")) {
      activelink = "more";
    }
    else if (window.location.pathname.includes("wallets")) {
      activelink = "wallets";
    }
    else if (window.location.pathname.includes("rametron")) {
      activelink = "rametron";
    }
    else if (window.location.pathname.includes("pnc20")) {
      activelink = "pnc20";
    }
    else {
      activelink = "";
    }

    let selectedLanguage = localStorage.getItem('currentLang')
    let selectedLanguageValue = localStorage.getItem('currentLanguage')

    if (!(!!selectedLanguage)) {
      selectedLanguage = 'en'
      selectedLanguageValue = 'English'
    }
    super(props);
    this.state = {
      addClass: false,
      lang: lang,
      languages: Languages,
      selectedLanguage: selectedLanguageValue.toString(),
      activeLink: activelink,
      lbankPrince: 0
    };
    this.props.i18n.changeLanguage(selectedLanguage);

    this.handleHeader = this.handleHeader.bind(this);
    this.lbank()
  }

  handleHeader(val) {

    $('.navbar-collapse').collapse('hide');

    this.setState({ addClass: !this.state.addClass });
    if (val == 'public-node' || val == 'wallets') {
      this.setState({ activeLink: null });
    }
  }

  lbank() {
    stakeService.lbank().then((data) => {
      this.setState({
        lbankPrince: data.lBankPrice[0].latestPrice
      })

    })
  }

  changeLanguage = (lang) => {
    for (const key in this.state.languages) {
      if (key === lang) {
        this.setState({ selectedLanguage: lang });
        localStorage.setItem('currentLang', this.state.languages[key])
        localStorage.setItem('currentLanguage', lang)
        this.props.i18n.changeLanguage(this.state.languages[key]);
      }
    }
  };

  handleClick = (id) => {
    $('.navbar-collapse').collapse('hide');
    //   var link = document.getElementById('tohes');

    //  link.click();
    this.setState({ activeLink: id });
  };
  render() {
    const { t } = this.props;
    let boxClass = [""];
    if (this.state.addClass) {
      boxClass.push("active-nav");
    }
    return (
      <div className="">
        <nav className="navbar navbar-expand-lg navbar-light new-hede fixed-top">
          <a className="navbar-brand" href="#">
            <Link to="/" className="navbar-brand" onClick={() => this.handleClick(" ")}>
              <img className="logo" src="/images/PANDOPROJECT LOGO.svg" height="50" alt="" />
              <div className="textsesconta">
              <span className="testsesx onsa">
                PTX Price: <b>${this.state.lbankPrince}</b>
              </span>
              <span className="testsesx">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-fuel-pump-fill" viewBox="0 0 16 16">
                  <path d="M1 2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v8a2 2 0 0 1 2 2v.5a.5.5 0 0 0 1 0V8h-.5a.5.5 0 0 1-.5-.5V4.375a.5.5 0 0 1 .5-.5h1.495c-.011-.476-.053-.894-.201-1.222a.97.97 0 0 0-.394-.458c-.184-.11-.464-.195-.9-.195a.5.5 0 0 1 0-1c.564 0 1.034.11 1.412.336.383.228.634.551.794.907.295.655.294 1.465.294 2.081V7.5a.5.5 0 0 1-.5.5H15v4.5a1.5 1.5 0 0 1-3 0V12a1 1 0 0 0-1-1v4h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V2Zm2.5 0a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5v-5a.5.5 0 0 0-.5-.5h-5Z" />
                </svg>  Gas: <b>4000 Gwei</b>
              </span>
              </div>

            </Link>
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto forweb90">

              <li onClick={() => this.handleClick("/")} className={this.state.activeLink === "/" ? " nav-item active" : "nav-item "}>
                <Link to="/" className="nav-link" onClick={() => this.handleHeader}>
                  Home
                </Link>
              </li>
              {/* <Link to="/" className="nav-link" >
                  Home {" "}
                </Link> */}

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Blockchain
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a className="dropdown-item" href="#">
                    <li onClick={() => this.handleClick("block")} className={this.state.activeLink === "block" ? " nav-item active" : "nav-item "}>
                      <Link to="/blocks" className="nav-link" onClick={() => this.handleHeader}>
                        {t("BLOCKS")}
                      </Link>
                    </li>
                  </a>
                  <a className="dropdown-item" href="#">
                    <li onClick={() => this.handleClick("txs")} className={this.state.activeLink === "txs" ? " nav-item active" : "nav-item "}>
                      <Link to="/txs" className="nav-link" onClick={() => this.handleHeader}>
                        {t("TRANSACTIONS")}
                      </Link>
                    </li>
                  </a>
                  <a className="dropdown-item" href="#">
                    <li onClick={() => this.handleClick("stakes")} className={this.state.activeLink === "stakes" ? " nav-item active" : "nav-item "}>
                      <Link to="/stakes" className="nav-link" onClick={() => this.handleHeader}>
                        {t("STAKES")}
                      </Link>
                    </li>
                  </a>

                  <a className="dropdown-item" href="#">
                    <li onClick={() => this.handleClick("nodes")} className={this.state.activeLink === "nodes" ? " nav-item active" : "nav-item "}>
                      <Link to="/nodes" className="nav-link" onClick={() => this.handleHeader}>
                        {t("TOP NODES")}
                      </Link>
                    </li>
                  </a>

                </div>
              </li>


              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Tokens
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a className="dropdown-item" href="#">
                    <li onClick={() => this.handleClick("pnc20")} className={this.state.activeLink === "pnc20" ? " nav-item active" : "nav-item "}>
                      <Link to="/pnc20" className="nav-link" onClick={() => this.handleHeader}>
                        {t("PNC20")}
                      </Link>
                    </li>
                  </a>

                  <a className="dropdown-item" href="#">
                    <li onClick={() => this.handleClick("pnc721")} className={this.state.activeLink === "pnc721" ? " nav-item active" : "nav-item "}>
                      <Link to="/pnc721" className="nav-link" onClick={() => this.handleHeader}>
                        {t("PNC721")}
                      </Link>
                    </li>
                  </a>


                </div>
              </li>



              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Resources </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a className="dropdown-item nav-link" href="https://wallet.pandoproject.org/" target="_blank">
                    Web wallet
                  </a>
                  <a className="dropdown-item nav-link" href="https://pandoproject.org/" target="_blank">
                    About
                  </a>
                  <a className="dropdown-item sgtd7 nav-link">
                    <div className="test-nt" >
                      <a href={config.redirectionUrl}
                        className="t4r" target="_blank">
                        {config.network}
                        <span> {config.version}</span>

                      </a>

                    </div>
                  </a>
                  <a className="dropdown-item " href="https://docs.pandoproject.org/" target="_blank">
                    <div className="rametronDownloads"
                    > Docs
                    </div>
                  </a>


                </div>
              </li>
            </ul>

            {/* for mobile view header */}

            <ul className="navbar-nav ml-auto formob91">
              <li onClick={() => this.handleClick(" ")} className={this.state.activeLink === " " ? " nav-item active" : "nav-item "}>
                <Link to="/" className="nav-link" onClick={() => this.handleHeader}>
                  Home
                </Link>
              </li>
              <li onClick={() => this.handleClick("block")} className={this.state.activeLink === "block" ? " nav-item active" : "nav-item "}>
                <Link to="/blocks" className="nav-link" onClick={() => this.handleHeader}>
                  {t("BLOCKS")}
                </Link>
              </li>
              <li onClick={() => this.handleClick("txs")} className={this.state.activeLink === "txs" ? " nav-item active" : "nav-item "}>
                <Link to="/txs" className="nav-link" onClick={() => this.handleHeader}>
                  {t("TRANSACTIONS")}
                </Link>
              </li>
              <li onClick={() => this.handleClick("stakes")} className={this.state.activeLink === "stakes" ? " nav-item active" : "nav-item "}>
                <Link to="/stakes" className="nav-link" onClick={() => this.handleHeader}>
                  {t("STAKES")}
                </Link>
              </li>
              <li onClick={() => this.handleClick("nodes")} className={this.state.activeLink === "nodes" ? " nav-item active" : "nav-item "}>
                <Link to="/nodes" className="nav-link" onClick={() => this.handleHeader}>
                  {t("TOP NODES")}
                </Link>
              </li>
              <li onClick={() => this.handleClick("pnc20")} className={this.state.activeLink === "pnc20" ? " nav-item active" : "nav-item "}>
                <Link to="/pnc20" className="nav-link" onClick={() => this.handleHeader}>
                  {t("PNC20")}
                </Link>
              </li>
              <li onClick={() => this.handleClick("pnc721")} className={this.state.activeLink === "pnc721" ? " nav-item active" : "nav-item "}>
                <Link to="/pnc721" className="nav-link" onClick={() => this.handleHeader}>
                  {t("PNC721")}
                </Link>
              </li>
              <li onClick={() => this.handleClick("https://wallet.pandoproject.org/")} className={this.state.activeLink === "https://wallet.pandoproject.org/" ? " nav-item active" : "nav-item "}>
                <Link href="https://pandoproject.org/" target="_blank" className="nav-link" onClick={() => this.handleHeader}>
                  Web wallet
                </Link>
              </li>
              <li onClick={() => this.handleClick("https://pandoproject.org/")} className={this.state.activeLink === "https://pandoproject.org/" ? " nav-item active" : "nav-item "}>
                <Link href="https://pandoproject.org/" target="_blank" className="nav-link" onClick={() => this.handleHeader}>
                  About
                </Link>
              </li>
              <li className="nav-item">
                <a className="dropdown-item sgtd7" href="#">
                  <div className="test-nt" >
                    <a href={config.redirectionUrl}
                      className="t4r" target="_blank">
                      {config.network}
                      <span> {config.version}</span>

                    </a>

                  </div>
                </a>
                <a className="dropdown-item " href="https://docs.pandoproject.org/" target="_blank">
                  <div className="rametronDownloads"
                  > Docs
                  </div>
                </a>
              </li>


            </ul>


          </div>
        </nav>



      </div>
    );
  }
}
export default withTranslation()(Header);
