import React, { Component } from "react";
import { browserHistory, Link } from 'react-router';
import cx from 'classnames';
import { blocksService } from '../common/services/block';
import NotExist from '../common/components/not-exist';
import { BlockStatus, TxnTypeText, TxnClasses } from '../common/constants';
import { date, hash, prevBlock } from '../common/helpers/blocks';
import { formatCoin, priceCoin } from '../common/helpers/utils';

import LoadingPanel from '../common/components/loading-panel';
import { withTranslation } from "react-i18next";
class BlocksExplorer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      block: null,
      totalBlocksNumber: undefined,
      errorType: null,
      price: {},
      loading: true
    };
    this.searchInput = React.createRef();
    this.searchType = React.createRef();
    this.handleSearch = this.handleSearch.bind(this);
  }
  componentWillUpdate(nextProps) {
    if (nextProps.params.blockHeight !== this.props.params.blockHeight) {
      this.getOneBlockByHeight(nextProps.params.blockHeight);
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const { blockHeight } = this.props.params;
    this.getOneBlockByHeight(blockHeight);

  }
  handleSearch() {
    const value = this.searchInput.value;
    switch (this.searchType.value) {
      case "address":
        if (value !== "") {
          browserHistory.push(`/account/${value}?tx=transfer`);
          this.searchInput.value = "";
        }
        break;
      case "block":
        browserHistory.push(`/blocks/${value}`);
        this.searchInput.value = "";
        break;
      case "transaction":
        browserHistory.push(`/txs/${value}`);
        this.searchInput.value = "";
        break;
      default:
        break;
    }
  }

  clearSearchBox = () => {
    if (this.searchInput.value) {
      this.searchInput.value = "";
    }
  };
  handleEnterKey(e) {
    if (e.key === "Enter") {
      this.handleSearch();
    }
  }

  getOneBlockByHeight(height) {
    const { totalBlocksNumber } = this.state;
    const msg = this.props.location.state;
    if (Number(height)
      && (totalBlocksNumber === undefined
        || totalBlocksNumber >= height
        || height > 0)) {
      this.setState({ loading: true });

      blocksService.getBlockByHeight(height)
        .then(res => {
          switch (res.data.type) {
            case 'block':
              this.setState({
                block: res.data.body,
                totalBlocksNumber: res.data.totalBlocksNumber,
                errorType: null,
                loading: false
              });
              break;
            case 'error_not_found':
              this.setState({
                errorType: msg ? 'error_coming_soon' : 'error_not_found'
              });
          }
        }).catch(err => {
        })
    } else {
      this.setState({
        errorType: 'error_not_found'
      });

    }
  }
  renderNoMoreMsg() {
    return (
      <div className="th-explorer__buttons--no-more">No More</div>
    )
  }

  render() {
    const { t } = this.props
    const { block, totalBlocksNumber, errorType, price } = this.state;
    const height = Number(this.props.params.blockHeight);
    const hasNext = totalBlocksNumber > height;
    const hasPrev = height > 1;
    const isCheckPoint = block && (block.total_voted_guardian_stakes != undefined);
    return (

      <div className="content block-details">
        <div className="explore-1 mb-5">

          <div className="searchContainer tdcg6 forcent">
          <div className="search-select">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
              </svg>
              <select ref={option => this.searchType = option} onChange={(e) => this.clearSearchBox()}  >

                <option value="address">{t('ADDRESS')}</option>
                <option value="block">{t('BLOCK_HEIGHT')}</option>
                <option value="transaction">{t('TRANSACTION')}</option>
              </select>

            </div>
            <div className="searchiconwrapper">
              <input type="text" className="search-input nwe1" placeholder="Search  by Address, Block Height, Transaction" ref={input => this.searchInput = input} onKeyPress={e => this.handleEnterKey(e)} />
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16" onClick={e => this.handleSearch()}>
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </div>

            

          </div>
        </div>

        <div className="page-title blocks">{t(`BLOCK_DETAILS`)}</div>
        {errorType === 'error_not_found' &&
          <NotExist />}
        {errorType === 'error_coming_soon' &&
          <NotExist msg="This block information is coming soon." />}
        {this.state.loading && !errorType ?
          <LoadingPanel />
          :
          <>
            {block &&
              <React.Fragment>

                <div>
                  <div className="txt-de2 table-responsive">
                    <table className="txn-info details">
                      <tbody className={cx({ 'cp': isCheckPoint })}>
                        <tr>
                          <th>{t(`HEIGHT`)}</th>
                          <td>{height}</td>
                        </tr>
                        <tr>
                          <th>{t(`Status`)}</th>
                          <td><p className="finaly">{BlockStatus[block.status]}</p></td>
                        </tr>
                        <tr>
                          <th>{t(`TIMESTAMP`)}</th>
                          <td>{date(block)}</td>
                        </tr>
                        <tr>
                          <th>{t(`HASH`)}</th>
                          <td>{hash(block)}</td>
                        </tr>
                        <tr>
                          <th>{t(`TRANSACTIONS`)}</th>
                          <td>{block.num_txs}</td>
                        </tr>
                        {isCheckPoint && <tr>
                          <th className="cp"># {t(`VOTED_METATRON_STAKES`)}</th>
                          <td>
                            <div className="currency PandoWei left">{formatCoin(block.total_voted_guardian_stakes)} {t(`Pando`)}</div>
                            <div className='price'>&nbsp;{`[\$${priceCoin(block.total_voted_guardian_stakes)} USD]`}</div>
                          </td>
                        </tr>}
                        {isCheckPoint && <tr>
                          <th className="cp"># {t(`DEPOSITED_METATRON_STAKES`)}</th>
                          <td>
                            <div className="currency PandoWei left">{formatCoin(block.total_deposited_guardian_stakes)} {t(`Pando`)}</div>
                            <div className='price'>&nbsp;{`[\$${priceCoin(block.total_deposited_guardian_stakes)} USD]`}</div>
                          </td>
                        </tr>}
                        <tr>
                          <th>{t(`PROPOSER`)}</th>
                          <td>  {<Link to={`/account/${block.proposer}?tx=transfer`}> {block.proposer}</Link>}</td>
                        </tr>
                        <tr>
                          <th>{t(`STATE_HASH`)}</th>
                          <td>{block.state_hash}</td>
                        </tr>
                        <tr>
                          <th>{t(`TXNS_HASH`)}</th>
                          <td>{block.transactions_hash}</td>
                        </tr>
                        <tr>
                          <th>{t(`PREVIOUS_BLOCK`)}</th>
                          <td> {<Link to={`/blocks/${height - 1}`}>{prevBlock(block)}</Link>}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <h3>{t(`TRANSACTIONS`)}</h3>
                  <div className="nbcd978">
                  <div className="button-list split for-web23">
                    {hasPrev &&
                      <Link className="btn icon prev" to={`/blocks/${height - 1}`}><i /></Link>}
                    {hasNext &&
                      <Link className="btn icon next" to={`/blocks/${height + 1}`}><i /></Link>}
                  </div>
                  <div className="txt-de2 nh6">
                    <table className="data transactions">
                      <tbody>
                        {_.map(block.txs, (t, i) => <Transaction t={t} key={i} txn={t} />)}
                      </tbody>
                    </table>
                  </div>
                  <div className="button-list split for-mob24">
                    {hasPrev &&
                      <Link className="btn icon prev" to={`/blocks/${height - 1}`}><i /></Link>}
                    {hasNext &&
                      <Link className="btn icon next" to={`/blocks/${height + 1}`}><i /></Link>}
                  </div>
                  </div>

                  
                </div>





              </React.Fragment>}
          </>
        }
        <div className="f909 top-60">
        <h4 className="br-rel">Browse related searches</h4>
        
        <div className="browss ">
          
          <div className="fdr4">
            <div className="de1">
              <a href="https://pandoproject.org/mainnet-3-0/" target="_blank"><button className="mant3">Mainnet 3.0</button></a>
              <a href=""><Link to="/txs" className="" ><button>Transactions</button></Link></a>
            </div>
            <div className="de1">
              <a href=""><Link to="/nodes" className="" ><button>Top Nodes</button></Link></a>
              <a href=""><Link to="/stakes" className="" ><button>Stakes</button></Link></a>
            </div>
            <div className="de2"></div>
          </div>
        </div>
        </div>
      </div>);
  }
}

const Transaction = ({ txn }) => {
  let { hash, type } = txn;
  return (
    <tr className="block-txn">
      <td className={cx("txn-type hrt5", TxnClasses[type])}><p>{TxnTypeText[type]}</p></td>
      <td className="hash overflow"><Link to={`/txs/${hash}`}>{hash}</Link></td>
    </tr>)
}
export default withTranslation()(BlocksExplorer)



