import React, { Component } from "react";
import { browserHistory, Link } from 'react-router';
import { stakeService } from '../common/services/stake';
import { sumCoin } from '../common/helpers/utils';
import StakesTable from "../common/components/stakes-table";
import LoadingPanel from '../common/components/loading-panel';
import cx from 'classnames';
import { withTranslation } from "react-i18next";

import BigNumber from 'bignumber.js';
import { rtservices } from "../common/services/rtservices";


class Nodes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stakes: [],
            totalStaked: 0,
            holders: [],
            percentage: [],
            sortedStakesByHolder: [],
            sortedStakesBySource: [],
            AllZetaHolder: [],
            AllMetaHolder: [],
            sorterdRCP: [],
            sorterdGCP: [],
            sorterdVCP: [],
            loading: true,
            totalMeta: 0,
            totalZyta: 0,
            remtronCount: 0
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getAllStakes();
        this.getMetaHolder();

// this.getRTHolder()

    }

    getMetaHolder() {
        this.setState({ loading: true });
        stakeService.totalMetaHolder().then(res => {
            this.setState({ totalMeta: res.totalHolders.response[0].total })

        })
        stakeService.totalZytaCHolder().then(res => {
            this.setState({ totalZyta: res.totalHolders.response[0].total, loading: false })

        })

    }

    // getRTHolder() {
    //     this.setState({ loading: true });
    //     rtservices.totalHolder().then(res => {
    //         let resData2 = res.count;
          
                  

    //     })
        
    // }
    getAllStakes() {
        this.setState({ loading: true });
        stakeService.getAllStake().then(res => {

            let insit = []
            for (let i of res.data.body) {

                let temp = { _id: i.type + '_' + i.holder, type: i.type, holder: i.holder, source: i.source, amount: i.amount, withdrawn: i.withdrawn, return_height: i.return_height }
                insit.push(temp)

            }

            const stakeList = insit

            let sum = stakeList.reduce((sum, info) => { return sumCoin(sum, info.amount) }, 0);
            let holderObj = stakeList.reduce((map, obj) => {

                if (!map[obj._id]) {
                    map[obj._id] = {
                        type: obj.type,
                        amount: 0,
                        address: obj.holder
                    };
                }
                map[obj._id].amount = sumCoin(map[obj._id].amount, obj.amount).toFixed()
                return map;
            }, {});


            let sortedStakesByHolder = Array.from(Object.keys(holderObj), key => {
                return { 'holder': holderObj[key].address, 'amount': holderObj[key].amount, 'type': holderObj[key].type }
            }).sort((a, b) => {
                return b.amount - a.amount
            })


            let sorterdVCP = []
            let sorterdGCP = []
            let sorterdRCP = []

            for (let i of sortedStakesByHolder) {
                if (i.type == 'vcp') {
                    sorterdVCP.push(i)
                }
                else if (i.type == 'gcp') {
                    sorterdGCP.push(i)
                }
                else {
                    sorterdRCP.push(i)
                }
            }

            let sumPercent = 0;
            let topList = sortedStakesByHolder.slice(0, 8).map(stake => {
                let obj = {};
                obj.holder = stake.holder;
                obj.percentage = new BigNumber(stake.amount).dividedBy(sum / 100).toFixed(6);
                sumPercent += obj.percentage - '0';
                return obj;

            }).concat({ holder: 'Rest Nodes', 'percentage': (100 - sumPercent).toFixed(6) })
            this.setState({
                stakes: stakeList,
                totalStaked: sum,
                holders: topList.map(obj => { return obj.holder }),
                percentage: topList.map(obj => { return (obj.percentage - '0') }),
                sortedStakesByHolder: sortedStakesByHolder,
                loading: false,
                sorterdRCP: sorterdRCP,
                sorterdGCP: sorterdGCP,
                sorterdVCP: sorterdVCP
            });
        })
            .catch(err => {

            });
    }

    render() {
        const { sortedStakesByHolder, totalStaked, sorterdRCP, sorterdGCP, sorterdVCP, totalMeta, totalZyta } = this.state;
        const { t } = this.props

        let isTablet = window.screen.width <= 768;
        const truncate = isTablet ? 10 : 20;
        return (
            <div className="content stakes">
                {this.state.loading ?
                    <LoadingPanel />
                    :
                    <>

                        <div className="demgraph">

                            <div className="token dashboard PTX">




                                {/* <div className="column">
                                    <Detail title={`${t('Rametron Nodes')}`} value={(32)} />


                                </div> */}

                            </div>

                        </div>


                        <div class="container12">

                            <ul class="nav nav-pills">

                                <li class="nav-item metaro">

                                    <a class="nav-link active" data-toggle="pill" href="#cuckoo" role="tab" aria-controls="pills-cuckoo" aria-selected="false">
                                        <img src="../images/new-design/Oval (2).svg" className="activ12" />
                                        <img src="../images/new-design/Oval (2).svg" className="activ13" />
                                        <div className="column">
                                            <Detail title={`${t('Metatron Nodes')}`} value={(totalMeta)} />
                                        </div>
                                    </a>
                                </li>
                                <li class="nav-item zytrron">

                                    <a class="nav-link " data-toggle="pill" href="#flamingo" role="tab" aria-controls="pills-flamingo" aria-selected="true">
                                        <img src="../images/new-design/image_2023_08_03T12_09_06_394Z.png" className="activ12" />
                                        <img src="../images/new-design/Oval (3).svg" className="activ13" />
                                        <div className="column">
                                            <Detail value={(totalZyta)} title={`${t('ZYTATRON Nodes')}`} />


                                        </div>
                                    </a>
                                </li>
                                <li class="nav-item remaa">

                                    <a class="nav-link" data-toggle="pill" href="#ostrich" role="tab" aria-controls="pills-ostrich" aria-selected="false">
                                        <img src="../images/new-design/image_2023_08_03T12_09_47_253Z.png" className="activ12" />
                                        <img src="../images/new-design/Oval (4).svg" className="activ13" />
                                        <div className="column">
                                        <Detail value={sorterdRCP.length} title={`${t('RAMETRON NODES')}`} />
                                        </div>
                                    </a>
                                </li>

                            </ul>
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="cuckoo" role="tabpanel" aria-labelledby="profile-tab">
                                    <div className="whsts topnode1 top-pad-60">
                                        <h1>Top Holder Nodes</h1>
                                    </div>
                                    <div className="table-container">

                                        <StakesTable type='guardian' stakes={sorterdGCP} totalStaked={totalStaked} truncate={truncate} />
                                    </div>
                                </div>
                                <div class="tab-pane fade " id="flamingo" role="tabpanel" aria-labelledby="flamingo-tab">
                                    <div className="whsts topnode2 top-pad-60">
                                        <h1>Top Holder Nodes</h1>
                                    </div>
                                    <div className="table-container ">
                                        <StakesTable type='validators' stakes={sorterdVCP} totalStaked={totalStaked} truncate={truncate} />
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="ostrich" role="tabpanel" aria-labelledby="ostrich-tab">
                                    <div className="whsts topnode3 top-pad-60">
                                        <h1>Top Holder Nodes</h1>
                                    </div>
                                    <div className="table-container">

                                        <StakesTable type='rametron' stakes={sorterdRCP} totalStaked={totalStaked} truncate={truncate} />
                                    </div>
                                </div>
                            </div>

                        </div>


                        {/* <button className="btn custom-btn cont-u8" title="Refresh" onClick={() => this.getAllStakes()}  ><img src="/images/Layer 2.svg" alt="" /></button> */}





                    </>
                }
                {/*  */}

                <div className="f909 top-60 text-left">
                    <h4 className="br-rel">Browse related searches</h4>

                    <div className="browss ">

                        <div className="fdr4">
                            <div className="de1">
                                <a href="https://pandoproject.org/mainnet-3-0/" target="_blank"><button className="mant3">Mainnet 3.0</button></a>
                                <a href=""><Link to="/txs" className="" ><button>Transactions</button></Link></a>
                            </div>
                            <div className="de1">
                                <a href=""><Link to="/nodes" className="" ><button>Top Nodes</button></Link></a>
                                <a href=""><Link to="/stakes" className="" ><button>Stakes</button></Link></a>
                            </div>
                            <div className="de2"></div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

const Detail = ({ title, value }) => {
    return (
        <div className="detail">
            <div className={cx("value", { price: title.includes('Price') })}>{value}</div>
            <div className="title">{title}</div>
        </div>
    );
}

export default withTranslation()(Nodes)

