import React, { Component } from "react";
import { Link, withRouter } from 'react-router';
import { blocksService } from '../common/services/block';
import TransactionTable1 from "../common/components/transactions1-table";
import BlocksTable1 from "../common/components/blocks-table1";
import TokenDashboard from "../common/components/token-dashboard";
import { transactionsService } from '../common/services/transaction';
import LoadingPanel from '../common/components/loading-panel';
import { withTranslation } from "react-i18next";
import RametronOverviewDashboard from './rametronsoverview';
import WalletsDashboard from './wallets';
import NodeStatusDashboard from './node-status';

const MAX_BLOCKS = 15
const NUM_TRANSACTIONS = 15;
let internal;
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pandoInfo: null,
      PTXInfo: null,

      blockHeight: 0,
      blockInfoList: [],
      currentPageNumber: 1,
      totalPageNumber: 0,
      transactions: [],
      currentPage: 1,
      totalPages: 0,
      loading: false,
      price: { 'Pando': 0 }
    };
    this.receivedBlocksEvent = this.receivedBlocksEvent.bind(this);
    this.handleGetBlocksByPage = this.handleGetBlocksByPage.bind(this)
  }



  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ loading: true })
    this.tst();
    internal = setInterval(() => {
      this.tst();
    }, 6000)

  }
  componentWillUnmount() {
    clearInterval(internal)
  }


  tst() {

    const { currentPageNumber } = this.state;
    const { currentPage } = this.state;
    blocksService.getBlocksByPage(currentPageNumber, MAX_BLOCKS)
      .then(res => {
        this.receivedBlocksEvent(res);
      }).catch(err => {

      })

    transactionsService.getTransactionsByPage(currentPage, NUM_TRANSACTIONS)
      .then(res => {
        if (res.data.type == 'transaction_list') {
          this.setState({
            transactions: _.orderBy(res.data.body, 'number', 'desc'),
            currentPage: _.toNumber(res.data.currentPageNumber),
            totalPages: _.toNumber(res.data.totalPageNumber),
            loading: false,
          })
        }



      })
      .catch(err => {
        this.setState({ loading: false });
      })
  }

  receivedBlocksEvent(data) {
    if (data.data.type == 'block_list') {
      this.setState({
        blockInfoList: data.data.body,
        currentPageNumber: data.data.currentPageNumber,
        totalPageNumber: data.data.totalPageNumber,
        loading: false

      })
    }
  }

  handleGetBlocksByPage(pageNumber) {
    blocksService.getBlocksByPage(pageNumber, MAX_BLOCKS)
      .then(res => {
        this.receivedBlocksEvent(res);
      }).catch(err => {
      })
  }

  render() {
    const { t } = this.props;
    const { pandoInfo, PTXInfo, blockInfoList, transactions, currentPage, totalPages, loading, price } = this.state;

    return (
      <div className="">
        <div className="top-imga">
          <img src="/images/new-design/16406729_rm378-09 1.png" alt="" />
        </div>

        <div className="content home1">



          <TokenDashboard type='PTX' t={t} tokenInfo={PTXInfo} />

          {this.state.loading ?

            <LoadingPanel /> :
            <div className="overview tab-bg newtag top-60">
              {/* <button className="btn custom-btn hom-ref" title="Refresh" onClick={() => this.tst()} ><img src="/images/Layer 2.svg" alt="" /></button> */}
              <div className="row">
                <div className="col-lg-6 clo-md-12">
                  <div className="table-responsive">
                    <div className="tbal-bl2">
                      <h2 className="page-title blocks blokss2"><Link to="/blocks"> {t('Latest Blocks')}</Link></h2>
                      <BlocksTable1 updateLive={true} t={t} blocks={blockInfoList} truncate={20} />
                      <div className="mor-lnk"><Link to="/blocks" className="more derf">{t('View all Blocks')}</Link></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 clo-md-12">
                  <div className="table-responsive">
                    <div className="tbal-ty2 " >
                      <h2 className="page-title transactions ui7 blokss2"><Link to="/txs">{t('Latest Transactions')}</Link></h2>
                      <TransactionTable1 transactions={transactions} updateLive={true} truncate={20} t={t} />
                      <div className="mor-lnk"><Link to="/txs" className="more derf">{t('View all Transactions')}</Link></div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="rant5">
                <h2>Rametron</h2>
                <div className="all-in1">
                  <h3>Your all-in-one platform for creators who want to unleash their creativity!</h3>
                  <p>
                    Secure, efficient blockchain software: Store, transcode, stream videos and earn
                    A blockchain powered software to store, transcode and stream videos and data files and generate rewards as PTX. There are 4 different types of Rametrons which you can
                  </p>
                </div>
              </div> */}
            </div>

          }



{/* 
          <div class="ramotbs top-60">
            <div class="row">
              <div class="col-12">
                <ul class="nav nav-pills ">
                  <li class="nav-item">
                    <a class="nav-link active" data-toggle="pill" href="#flamingo" role="tab" aria-controls="pills-flamingo" aria-selected="true">Overview</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-toggle="pill" href="#cuckoo" role="tab" aria-controls="pills-cuckoo" aria-selected="false">Public Node</a>
                  </li>
                </ul>
                <div class="tab-content mt-3">
                  <div class="tab-pane fade show active" id="flamingo" role="tabpanel" aria-labelledby="flamingo-tab">
                    <RametronOverviewDashboard></RametronOverviewDashboard>
                    <div className="demgraph netwrokmap">
                      <div className="title pub2"><p><img src="../images/Rametron.svg" />{t(`NETWORKS`)}</p></div>
                      <iframe src="network.html" className="frams"></iframe>
                      <div className="legends">
                        <span className="rtent">RT Enterprise</span>
                        <span className="rtpro">RT Pro</span>
                        <span className="rtlite">RT Lite</span>
                        <span className="rtmobile">RT Mobile</span>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="cuckoo" role="tabpanel" aria-labelledby="profile-tab">
                    <NodeStatusDashboard></NodeStatusDashboard>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="whsts top-60">
            <h1>Wallet Overview</h1>
          </div>

          <div id="collapse-2" class="collapse forheight" data-parent="#accordion" aria-labelledby="heading-1">
            <div className="wallet5">
              <WalletsDashboard></WalletsDashboard>
            </div>
          </div>

          {/* view more less code */}




        </div>
      </div>
    );
  }
}
export default withTranslation()(Dashboard)