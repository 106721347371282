import React from 'react';
import ReactDom from 'react-dom';
import { Router, Route, IndexRoute, browserHistory } from 'react-router';

import App from './app';
import Home from './sections/home';
import Transactions from './sections/transactions'
import TransactionDetails from './sections/transaction-details'
import Blocks from './sections/blocks'
import BlockDetails from './sections/block-details'
import AccountDetails from './sections/account-details';

import SmartContractDetails from './sections/contract-details';


// import Downloads from './sections/downloads'
import Stakes from './sections/stakes'
import Wallets from './sections/wallets';
import Nodes from './sections/nodes';
import './styles/styles.scss'
import TokenDetails from './sections/token-details';
import "./i18n.js"
// import NodeStatus from './sections/node-status';
// import CheckStatus from './sections/check-status'
// import RametronDashboard from './sections/rametrons'
// import RametronOverviewDashboard from './sections/rametronsoverview'
import PNC20 from './sections/pnc20';
import PNC721 from './sections/pnc721';
ReactDom.render(
  
  <Router history={browserHistory}>
    <Route path='/' component={App}>
      <IndexRoute component={Home}  />
      <Route path='/dashboard' component={Home}  />
      <Route path='/blocks' component={Blocks}  />
      <Route path='/blocks/:blockHeight' component={BlockDetails}  />
      <Route path='/block/:blockHeight' component={BlockDetails}  />
      <Route path='/txs' component={Transactions}  />
      <Route path='/txs/:transactionHash' component={TransactionDetails}  />
      <Route path='/tx/:transactionHash' component={TransactionDetails} />
      <Route path='/account/:accountAddress' component={AccountDetails} />
      <Route path='/address/:accountAddress' component={AccountDetails} />
      <Route path='/contract/:accountAddress' component={SmartContractDetails} />
      <Route path='/stakes' component={Stakes}/>
      <Route path='/nodes' component={Nodes}/>
      {/* <Route path='/public-node' component={NodeStatus} /> */}
      {/* <Route path='/wallets' component={Wallets}/> */}
      {/* <Route path='/CheckStatus' component={CheckStatus}/> */}
      {/* <Route path='/rametronnetwork' component={RametronDashboard}/> */}
      <Route path='/pnc20' component={PNC20}/>
      <Route path='/pnc721' component={PNC721}/>
      <Route path='/token/:contractAddress' component={TokenDetails} />
      
    </Route>
  </Router>,
  document.getElementById('app-root')
);
