import React, { Component } from "react";
import { Link } from "react-router";
import cx from 'classnames';
import { formatCoin, sumCoin } from '../helpers/utils';
import { withTranslation } from "react-i18next";
import { formatQuantity } from '../helpers/utils';

const TRUNC = 20;

class TokenTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSliced: true,
      token: this.props.tokenlist.slice(0, TRUNC),
      currentPage: 1,
      totalPages: 0,
      loading: false

    };

  }
  static defaultProps = {

  }
  componentDidMount() {
  }
  toggleList() {
    if (this.state.isSliced) {
      this.setState({ token: this.props.tokenlist, isSliced: false })
    } else {
      this.setState({ token: this.props.tokenlist.slice(0, TRUNC), isSliced: true })
    }
  }

  componentWillUpdate(nextProps) {
    if (nextProps.tokenlist.length !== this.props.tokenlist.length) {
      this.setState({ token: nextProps.tokenlist.slice(0, TRUNC), isSliced: true })
    }
  }

  render() {
    const { className, type, truncate, totalStaked, tokenlist, t } = this.props;
    const { token, isSliced, remetron } = this.state;
  
    let colSpan = type === 'node' ? 4 : 4;

 
      return (

        <div className="stakes trnaj">
          {/* <div className="title mt-5"><p className="ac-det">{t(`TOP_ZYTATRON_NODES`)}</p></div> */}
          <div className="txt-de2 mt-4 table-responsive pt-0">
          <table className={cx("data txn-table2 ssds", className)}>
            <thead style={{color:"aqua"}}>
              <tr onClick={this.toggleList.bind(this)}>
                <th className="address"><p>{t(`Name`)}</p></th>
                <th className="staked"><p>{t(`Contract Address`)}</p></th>
                <th className="staked-prct"><p>{t(`Total Supply`)}</p></th>
                <th className="staked-prct"><p>{t(`Total Holders`)}</p></th>
                <th className="staked-prct"><p>{t(`Decimals`)}</p></th>
                <th className="staked-prct"><p>{t(`Total Transfers`)}</p></th>
                {/* <th className="staked-prct"><p>{t(`Token Type`)}</p></th> */}
              </tr>
            </thead>
          

            <tbody className="stake-tb">
              {_.map(token, record => {
              const quantity = record.decimals ? formatQuantity(record.max_total_supply, record.decimals) : txn.max_total_supply;
               
                  return (
                    <tr key={record.contract_address}>
                      <td className="address"><Link to={`/token/${record.contract_address}`}>{record.name}</Link> </td>
                      <td className="address"><Link to={`/token/${record.contract_address}`}>{_.truncate(record.contract_address, { length: truncate })}</Link></td>
                      
                      <td className="staked">{quantity} </td>
                      <td className="staked">{record.holders} </td>
                      <td className="staked">{record.decimals} </td>
                      <td className="staked">{record.total_transfers} </td>
                      {/* <td className="staked">{record.type} </td> */}
                    </tr>);
               
              })}
              {tokenlist.length > TRUNC &&
                <tr>
                  <td className="arrow-container" colSpan={colSpan} onClick={this.toggleList.bind(this)}>
                    {t(`VIEW`)} {isSliced ? `${t(`MORE`)}` : `${t(`LESS`)}`}
                  </td>
                </tr>
              }
              {
                tokenlist.length < 1 &&
                
                  <tr><td className="text-center" colSpan='6'>No Records found!</td></tr> 
                
              }
            
            
            </tbody>
          </table>
          </div>
        </div>

   


            )}
}

export default withTranslation()(TokenTable)
