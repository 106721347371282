module.exports = {
    restApi: {
        host: 'https://prodexplorer.pandoproject.org',
        port: ''
    },
    rtservices:'https://rtservices.pandoproject.org/apis',
    network:'Mainnet',
    version: 'Version 3.0',
    redirectionUrl:'https://pandoproject.org/mainnet-3-0/',
    downloadurl:'',
    auth:'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InB1YmxpY3VzZXJAcGFuZG9wcm9qZWN0Lm9yZyIsImlhdCI6MTY3NzkwODUyOCwiZXhwIjoxNjg1MDc5NzI4fQ.NVZWz9GUg9dDFnzkD770yTrrfqVsSq1izOQnUmDaA3Q',
    allinoneguide:'https://docs.pandoproject.org/',
    linuxUrl : '',
    macUrl: '',
    windowUrl: '',
    rpcUrl:'https://eth-rpc-api.pandoproject.org/rpc',
    BASE_URL: "https://mainnetapi.pandoproject.org/apis",
    chainId:"pandonet",
    pnc20list: ['0x58d2ded330a46bce6167222ce7b960fc1df20cc8']
}
