import { apiService, rtapiService } from './api';
import config from '../../config'
export const rtservices = {
  getPublicNodes(page) {
    return rtapiService.get(`get_publicNode?pages=` + page, { headers: { 'Authorization': config.auth } }).then((res) => res);
  },
  getTransactionHistory(address, startDate, endDate) {
    if (!address) {
      throw Error('Missing argument');
    }
    return rtapiService.get(`accountTx/history/${address}`, { params: { startDate, endDate } });
  },

  totalNodetype() {
    return rtapiService.get(`get_rametron_totaltypes`, { headers: { 'Authorization': config.auth } }).then((data) => data.data)
  },
  getRametronListForType(type) {
    return rtapiService.get(`RTpool_allDetails?type=` + type, { headers: { 'Authorization': config.auth } }).then((data) => data.data)
  },
  rtLiteStat()
  {
    return rtapiService.get(`rtLiteStat`, { headers: { 'Authorization': config.auth } }).then((data) => data.data)
  },
  // RT_LIte_stat_api_with_country()
  // {
  //   return rtapiService.get(`rtLiteProcessedCountry`, { headers: { 'Authorization': config.auth } }).then((data) => data.data)
  // },
  // rtLitePOE()
  // {
  //   return rtapiService.get(`rtLitePOE`, { headers: { 'Authorization': config.auth } }).then((data) => data.data)
  // },
  rtProStat()
  {
    return rtapiService.get(`rtProStat`, { headers: { 'Authorization': config.auth } }).then((data) => data.data)
  },
  // rtProMonthlyStat()
  // {
  //   return rtapiService.get(`monthlyRtProStat`, { headers: { 'Authorization': config.auth } }).then((data) => data.data)
  // }
  rtMobileStat()
  {
    return rtapiService.get(`rtMobileStat`, { headers: { 'Authorization': config.auth } }).then((data) => data.data)
  },
  rtLiveStat()
  {
    return rtapiService.get(`rtLiveStat`, { headers: { 'Authorization': config.auth } }).then((data) => data.data)
  },
  totalHolder()
  {
    return rtapiService.get(`rtCount`, { headers: { 'Authorization': config.auth } }).then((data) => data.data)
  },
  
};
