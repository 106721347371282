import React, { Component } from "react";
import { browserHistory, Link } from 'react-router';
import { stakeService } from '../common/services/stake';
import { sumCoin } from '../common/helpers/utils';
import TokenTable from "../common/components/token-table";
import LoadingPanel from '../common/components/loading-panel';
import cx from 'classnames';
import { withTranslation } from "react-i18next";
import config from "../config";
import BigNumber from 'bignumber.js';
import { tokenService } from "../common/services/token";


class PNC20 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tokenList:config.pnc20list,
            datatoken:[]
           };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getTokensHolder()

    }


    getTokensHolder() {
        this.setState({ loading: true });
        tokenService.getTokenInfoByAddressList(this.state.tokenList).then(res => {
            let resData2 = res.data.body;
           this.setState({ datatoken: resData2, loading: false });

        })

    }

    render() {
        const { datatoken } = this.state;
        const { t } = this.props

        let isTablet = window.screen.width <= 768;
        const truncate = isTablet ? 10 : 20;
        return (
            <div className="content stakes">
                {this.state.loading ?
                    <LoadingPanel />
                    :
                    <>

                        <div class="container12">
                            <ul class="nav nav-pills">
                                <li class="nav-item remaa pnc20">
                                    <a class="nav-link" data-toggle="pill" >
                                        <img src="../images/new-design/image_2023_08_03T12_09_47_253Z.png" className="activ12" />
                                        <img src="../images/new-design/Oval (4).svg" className="activ13" />
                                        <div className="column">
                                            <Detail value={datatoken.length} title={`${t('Total Tokens')}`} />
                                        </div>
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="cuckoo" role="tabpanel" aria-labelledby="profile-tab">
                                    <div className="whsts topnode6 top-pad-60">
                                        <h1>PNC 20 Tokens</h1>
                                    </div>
                                    <div className="table-container">
                                        <TokenTable type='guardian' tokenlist={datatoken}  truncate={truncate} />
                                    </div>
                                </div>

                            </div>

                        </div>


                        {/* <button className="btn custom-btn cont-u8" title="Refresh" onClick={() => this.getAllStakes()}  ><img src="/images/Layer 2.svg" alt="" /></button> */}





                    </>
                }
                {/*  */}

                <div className="f909 top-60 text-left">
                    <h4 className="br-rel">Browse related searches</h4>

                    <div className="browss ">

                        <div className="fdr4">
                            <div className="de1">
                                <a href="https://pandoproject.org/mainnet-3-0/" target="_blank"><button className="mant3">Mainnet 3.0</button></a>
                                <a href=""><Link to="/txs" className="" ><button>Transactions</button></Link></a>
                            </div>
                            <div className="de1">
                                <a href=""><Link to="/nodes" className="" ><button>Top Nodes</button></Link></a>
                                <a href=""><Link to="/stakes" className="" ><button>Stakes</button></Link></a>
                            </div>
                            <div className="de2"></div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

const Detail = ({ title, value }) => {
    return (
        <div className="detail">
            <div className={cx("value", { price: title.includes('Price') })}>{value}</div>
            <div className="title">{title}</div>
        </div>
    );
}

export default withTranslation()(PNC20)

