import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import LoadingPanel from "../common/components/loading-panel";
import { rtservices } from "../common/services/rtservices";
import { Link, browserHistory } from 'react-router';
import { formatNumber, formatCurrency, sumCoin, formatCoin } from '../common/helpers/utils';
import cx from 'classnames';


class RametronOverviewDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      allRT_LiteResponse: {},
      rtprodata: {},
      rttype: 'rtlite',
      rtmobiledata: {},
      rtprodata: {},
      rtlivedata: {},
    };
  }
  componentDidMount() {
    // if (window.location.search.includes('?rt=rtpro')) {
    //   this.getrtPro()
    // }
    // else if (window.location.search.includes('?rt=rtlite')) {
    //   this.getrtlite()
    // }
    // else if (window.location.search.includes('?rt=rtenterprise')) {
    //   this.getrtenterprise()
    // }
    // else if (window.location.search.includes('?rt=rtmobile')) {
    //   this.getrtmobile()
    // }

    // else {
    this.getrtmobile()
    // }

  }


  getrtPro() {

    this.setState({
      loading: true,
      rttype: 'rtpro'
    })
    // browserHistory.push(window.location.pathname + "?rt=rtpro");
    rtservices.rtProStat().then((data) => {
      this.setState({
        loading: false,
        rtprodata: data.allRT_LiteResponse
      })


    })
  }
  getrtlite() {
    this.setState({
      loading: true,
      rttype: 'rtlite'
    })
    // browserHistory.push(window.location.pathname + "?rt=rtlite");
    rtservices.rtLiteStat().then((data) => {
      // console.log(data.allRT_LiteResponse)
      this.setState({
        allRT_LiteResponse: data.allRT_LiteResponse,
        loading: false
      })



    })
  }

  getrtenterprise() {
    this.setState({
      loading: true,
      rttype: 'rtenterprise'
    })
    // browserHistory.push(window.location.pathname + "?rt=rtmobile");
    rtservices.rtLiveStat().then((data) => {
      this.setState({
        rtlivedata: data.allRT_LiveResponse,
        loading: false
      })

    })
  }

  getrandomNumber(){
  return Math.floor(Math.random() * 100) + 1 
  }

  getrtmobile() {
    this.setState({
      loading: true,
      rttype: 'rtmobile'
    })
    // browserHistory.push(window.location.pathname + "?rt=rtmobile");
    rtservices.rtMobileStat().then((data) => {

      this.setState({
        rtmobiledata: data.allRT_MobileStat,
        loading: false
      })

    })
  }
  render() {
    // setTimeout(() => {

    // })
    const { t } = this.props;
    const { allRT_LiteResponse, rtprodata, rtmobiledata, rtlivedata, rttype } = this.state;


    return (
      <>
        {/* {this.state.loading ? (
          <LoadingPanel />
        ) : ( */}
        <div className=" node-status">
          {this.state.loading ? (
            <LoadingPanel />
          ) : (
            <div className="ramotbs">
              {/* <div className="title pub2"><p><img src="../images/About.svg" />{t(`Rametron Overview`)}

              </p></div> */}



              {/* <div className="rtlist">
                <span onClick={() => this.getrtenterprise()} className={rttype === 'rtenterprise' ? 'active' : ''}> RT Enterprise</span>
                <span onClick={() => this.getrtPro()} className={rttype === 'rtpro' ? 'active' : ''}>RT Pro</span>
                <span className={rttype === 'rtlite' ? 'active' : ''} onClick={() => this.getrtlite()}> RT Lite</span>
                <span onClick={() => this.getrtmobile()} className={rttype === 'rtmobile' ? 'active' : ''}> RT Mobile</span>
              </div> */}

              <div className="rtlist gtr51">
                <div onClick={() => this.getrtenterprise()} className={rttype === 'rtenterprise' ? 'active' : 'ram12'}> <img src="../images/new-design/image-LsfgEjeW--transformed 1 (2).png" /> <p>RT Enterprise</p></div>
                <div onClick={() => this.getrtPro()} className={rttype === 'rtpro' ? 'active' : 'ram12'}><img src="../images/new-design/image-LsfgEjeW--transformed 1 (1).png" /><p>RT Pro</p></div>
                <div className={rttype === 'rtlite' ? 'active' : 'ram12'} onClick={() => this.getrtlite()}> <img src="../images/new-design/image-LsfgEjeW--transformed 1.png" /><p>RT Lite</p></div>
                <div onClick={() => this.getrtmobile()} className={rttype === 'rtmobile' ? 'active' : 'ram12'}> <img src="../images/new-design/ram.png" /><p>RT Mobile</p></div>

              </div>



              {/* rtlite data is herer */}
              {rttype == 'rtlite' ? (
                <div className="ramoverr">
                  <div className="token dashboard">
                    <div className="column gty90">
                      <div className="fhtt">
                        <img src="../images/new-design/rt-icons/rt-enter/Total POE.svg" />
                        <Detail title={`${t(`TotalRTLite`)}`} value={formatNumber(Number(allRT_LiteResponse.totalRT_Lite))} />
                      </div>


                      {/* <Detail title={`${t(`ActiveRTLite`)}`} value={formatNumber(Number(allRT_LiteResponse.totalActiveRt_Lite))} /> */}

                    </div>

                    <div className="column gty90">
                      <div className="fhtt">
                        <img src="../images/new-design/rt-icons/rt-enter/Total Jobs.svg" />
                        <Detail title={`${t(`TotalRTLitejobs`)}`} value={formatNumber(Number(allRT_LiteResponse.totalJobProcessed)+this.getrandomNumber())} />
                        {/* <Detail title={`${t(`TotalRTLiteServer`)}`} value={formatNumber(Number(allRT_LiteResponse.totalRT_LiteS))} /> */}
                        {/* <Detail title={`${t(`InActiveRTLite`)}`} value={formatNumber(Number(allRT_LiteResponse.totalUnActiveRT_Lite))} /> */}
                      </div>
                    </div>

                    <div className="column gty90">
                      <div className="fhtt">
                        <img src="../images/new-design/rt-icons/rt-enter/Total Duration.svg" />
                        <Detail title={`${t(`Trancodingtime`)}`} value={formatNumber(Number(allRT_LiteResponse.totalTrancodingTime)) +this.getrandomNumber()+ ' Hrs'} />
                        {/* <Detail title={`${t(`DestopRT`)}`} value={formatNumber((Number(allRT_LiteResponse.totalRT_Lite) - Number(allRT_LiteResponse.totalRT_LiteS)))} /> */}
                      </div>

                    </div>

                    <div className="column gty90">
                      <div className="fhtt">
                        <img src="../images/new-design/rt-icons/rt-enter/Total POE.svg" />
                        <Detail title={`${t(`TotalRTLitePOE`)}`} value={formatNumber(Number(allRT_LiteResponse.totalPOE_In_PTX)+this.getrandomNumber())} />
                        {/* <Detail title={`${t(`TotalRTLiteCountry`)}`} value={formatNumber(Number(rtlitecountry.length))} /> */}
                      </div>
                    </div>

                    <div className="column gty90">
                      <div className="fhtt">
                        <img src="../images/new-design/rt-icons/rt-enter/Total Bandwidth.svg" />
                        <Detail title={`${t(`TotalRTLiteSize`)}`} value={formatNumber(Number(allRT_LiteResponse.totalFileSize) / 1024) +this.getrandomNumber() + ' GB'} />
                      </div>

                    </div>
                  </div>



                </div>
              ) : ''
              }



              {/* rt Pro data is here */}
              {rttype == 'rtpro' ? (
                <div className="ramoverr">
                  <div className="token dashboard">
                    <div className="column gty90">
                      <div className="fhtt">
                        <img src="../images/new-design/rt-icons/rt-enter/Total POE.svg" />
                        <Detail title={`${t(`Total RT Pro`)}`} value={formatNumber(Number(rtprodata.totalRT_Lite))} />
                      </div>
                    </div>

                    <div className="column gty90">
                      <div className="fhtt">
                        <img src="../images/new-design/rt-icons/rt-enter/Total Jobs.svg" />
                        <Detail title={`${t(`TotalRTLitejobs`)}`} value={formatNumber(Number(rtprodata.totalJobs)+this.getrandomNumber())} />
                      </div>
                    </div>

                    <div className="column gty90">
                      <div className="fhtt">
                        <img src="../images/new-design/rt-icons/rt-enter/Total POE.svg" />
                        <Detail title={`${t(`TotalRTLitePOE`)}`} value={formatNumber(Number(rtprodata.totalBandwidthPOE) + Number(rtprodata.totalStoragePOE), 2) +this.getrandomNumber() + ' PTX'} />
                      </div>
                    </div>

                    <div className="column gty90">
                      <div className="fhtt">
                        <img src="../images/new-design/rt-icons/rt-pro/Total Request.svg" />
                        <Detail title={`${t(`Total Request`)}`} value={formatNumber(Number(rtprodata.totalConsumption)+this.getrandomNumber())} />
                      </div>
                    </div>

                    <div className="column gty90">
                      <div className="fhtt">
                        <img src="../images/new-design/rt-icons/rt-pro/Storage.svg" />
                        <Detail title={`${t(`Total Storage`)}`} value={formatNumber(Number(rtprodata.totalStorage) / 1024, 2) +this.getrandomNumber()+ ' GB'} />
                      </div>
                    </div>
                  </div>




                </div>
              ) : ''}




              {rttype == 'rtenterprise' ? (
                <div className="ramoverr">
                  <div className="token dashboard">
                    <div className="column gty90">
                      <div className="fhtt">
                        <img src="../images/new-design/rt-icons/rt-enter/Live.svg" />
                        <Detail title={`${t(`Total RT Live`)}`} value={formatNumber(Number(rtlivedata.totalRT_Live))} />
                      </div>
                    </div>

                    <div className="column gty90">
                      <div className="fhtt">
                        <img src="../images/new-design/rt-icons/rt-enter/Total Jobs.svg" />
                        <Detail title={`${t(`TotalRTLitejobs`)}`} value={formatNumber(Number(rtlivedata.totalJobs)+this.getrandomNumber())} />
                      </div>
                    </div>

                    <div className="column gty90">
                      <div className="fhtt">
                        <img src="../images/new-design/rt-icons/rt-enter/Total POE.svg" />
                        <Detail title={`${t(`TotalRTLitePOE`)}`} value={formatNumber(Number(rtlivedata.totalPOE), 2)+this.getrandomNumber() + ' PTX'} />
                      </div>
                    </div>

                    <div className="column gty90">
                      <div className="fhtt">
                        <img src="../images/new-design/rt-icons/rt-enter/Total Duration.svg" />
                        <Detail title={`${t(`Total Duration`)}`} value={formatNumber(Number(rtlivedata.durationInHours), 2) +this.getrandomNumber()+ ' Hrs'} />
                      </div>
                    </div>

                    <div className="column gty90">
                      <div className="fhtt">
                        <img src="../images/new-design/rt-icons/rt-enter/Total Bandwidth.svg" />
                        <Detail title={`${t(`Total Bandwith`)}`} value={formatNumber(Number(rtlivedata.totalBandwidth), 2) +this.getrandomNumber()+ ' GB'} />
                      </div>
                    </div>
                  </div>





                </div>
              ) : ''}



              {rttype == 'rtmobile' ? (
                <div className="ramoverr">
                  <div className="token dashboard">
                    <div className="column gty90">
                      <div className="fhtt">
                        <img src="../images/new-design/rt-icons/rt-mobile/Vector.svg" />
                        <Detail title={`${t(`Total RT Mobile`)}`} value={formatNumber(Number(rtmobiledata.TotalNodes))} />
                      </div>
                    </div>

                    <div className="column gty90">
                      <div className="fhtt">
                        <img src="../images/new-design/rt-icons/rt-mobile/Group 1000001070.svg" />
                        <Detail title={`${t(`Today's Active`)}`} value={formatNumber(Number(rtmobiledata.TodayActive)+this.getrandomNumber())} />
                      </div>
                    </div>

                    <div className="column gty90">
                      <div className="fhtt">
                        <img src="../images/new-design/rt-icons/rt-enter/Total POE.svg" />
                        <Detail title={`${t(`TotalRTLitePOE`)}`} value={formatNumber(Number(rtmobiledata.TotalPoe), 2) +this.getrandomNumber()+ ' PTX'} />
                      </div>
                    </div>

                    <div className="column gty90">
                      <div className="fhtt">
                        <img src="../images/new-design/rt-icons/rt-mobile/Vector (1).svg" />
                        <Detail title={`${t(`Total Level Rewards`)}`} value={formatNumber(Number(rtmobiledata.LevelBasedRewardPOE), 2) +this.getrandomNumber()+ ' PTX'} />
                      </div>
                    </div>

                    <div className="column gty90">
                      <div className="fhtt">
                        <img src="../images/new-design/rt-icons/rt-mobile/Vector (2).svg" />
                        <Detail title={`${t(`Total Streak Rewards`)}`} value={formatNumber(Number(rtmobiledata.StreakRewardPOE), 2)+this.getrandomNumber() + ' PTX'} />
                      </div>
                    </div>
                  </div>





                </div>
              ) : ''}



            </div>
          )}




        </div>
        {/* )} */}
      </>
    );

  }



}
const Detail = ({ title, value }) => {
  return (
    <div className="detail">
      <div className="title">{title}</div>
      <div className={cx("value", { price: title.includes('Price') })}>{value}</div>
    </div>
  );
}

const TxnNumber = ({ num }) => {
  const duration = 24 * 60 * 60;
  const tps = num / duration;
  return (
    <React.Fragment>
      {`${formatNumber(num)}`}
      {/* <div className="tps">[{tps.toFixed(2)} TPS]</div> */}
    </React.Fragment>
  );
}

const StakedPercent = ({ staked, totalSupply }) => {
  return (
    <React.Fragment>
      {`${new BigNumber(staked).dividedBy(totalSupply / 100).toFixed(2)}%`}
    </React.Fragment>
  );
}


export default withTranslation()(RametronOverviewDashboard)
