import React, { Component } from "react";
import { blocksService } from '../common/services/block';
import { browserHistory, Link } from 'react-router';
import BlocksTable from "../common/components/blocks-table";
import Pagination from "../common/components/pagination";
import LoadingPanel from '../common/components/loading-panel';
import { withTranslation } from "react-i18next";


const MAX_BLOCKS = 20;
let interval;
class Blocks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blockHeight: 0,
      blockInfoList: [],
      currentPageNumber: 1,
      totalPageNumber: 0,
      count: 0,
      loading: true
    };
    ;
    this.receivedBlocksEvent = this.receivedBlocksEvent.bind(this);
    this.handleGetBlocksByPage = this.handleGetBlocksByPage.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { currentPageNumber } = this.state;
    this.setState({ loading: true });
    this.test(currentPageNumber)
    interval = setInterval(() => {
      this.test(this.state.currentPageNumber)
    }, 6000)
  }

  test(currentPageNumber) {

    this.setState({ currentPageNumber: currentPageNumber })
    blocksService.getBlocksByPage(currentPageNumber, MAX_BLOCKS)
      .then(res => {

        this.receivedBlocksEvent(res);
        this.setState({ loading: false });

      }).catch(err => {

      })

  }

  componentWillUnmount() {
    clearInterval(interval);
  }
  test1(currentPageNumber) {
    this.setState({ loading: true });
    this.setState({ currentPageNumber: currentPageNumber })
    blocksService.getBlocksByPage(currentPageNumber, MAX_BLOCKS)
      .then(res => {
        this.setState({ loading: false });

        this.receivedBlocksEvent(res);


        // }
      }).catch(err => {

      })

  }
  receivedBlocksEvent(data) {
    if (data.data.type == 'block_list') {
      this.setState({
        blockInfoList: data.data.body,
        totalPageNumber: data.data.totalPageNumber
      })

    }
  }
  handleGetBlocksByPage(pageNumber) {
    window.scrollTo(0, 0);
    this.test1(pageNumber)
  }
  render() {
    const { t } = this.props;
    const { blockInfoList } = this.state;
    let { currentPageNumber, totalPageNumber } = this.state;
    currentPageNumber = Number(currentPageNumber);
    totalPageNumber = Number(totalPageNumber);
    return (
      <div className="content blocks">
          <div className="whsts blckss top-pad-60">
                <h1>Blocks</h1>
          </div>
        {/* <div className="page-title blocks"><img src="../images/icons/Icon awesome-boxes.svg" /> {t('BLOCKS')}  */}
         {/* <button className="btn custom-btn" onClick={() => this.test(this.state.currentPageNumber)} title="Refresh" >
          <img src="/images/Layer 2.svg" alt="" /></button> */}
          {/* </div> */}
        <div className="table-responsive backgr">
        <div className="blk9">
          {this.state.loading ?
            <LoadingPanel /> :
            <>
              <BlocksTable
                blocks={blockInfoList}
                truncate={20}
                t={t}
              />
              <Pagination
                size={'lg'}
                totalPages={totalPageNumber}
                currentPage={currentPageNumber}
                onPageChange={this.handleGetBlocksByPage}
              />
            </>
          }


        </div>
        </div>
        <div className="f909 top-60">
        <h4 className="br-rel">Browse related searches</h4>
        
        <div className="browss ">
          
        <div className="fdr4">
            <div className="de1">
              <a href="https://pandoproject.org/mainnet-3-0/" target="_blank"><button className="mant3">Mainnet 3.0</button></a>
              <a href=""><Link to="/txs" className="" ><button>Transactions</button></Link></a>
            </div>
            <div className="de1">
              <a href=""><Link to="/nodes" className="" ><button>Top Nodes</button></Link></a>
              <a href=""><Link to="/stakes" className="" ><button>Stakes</button></Link></a>
            </div>
            <div className="de2"></div>
          </div>
        </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(Blocks)