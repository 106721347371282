import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import LoadingPanel from "../common/components/loading-panel";
import { stakeService } from "../common/services/stake";
import { Link } from 'react-router';
import { formatNumber } from '../common/helpers/utils';

import { rtservices } from "../common/services/rtservices";
class NodeStatus extends Component {
  constructor(props) {
    super(props);
   
    this.state = {
      loading: false,
      nodeDetails: null,
      showDetails: false,
      ip: null,
      required: false,
      errorMsg: false,
      publicnnode: [],
      currentPage: 1,
      totalPages: 1,
      loading_txns: true,
      nodetype: [],
      activeType: ''

    };
  }
  componentDidMount() {
    this.tst()
    this.setState({ showDetails: false });


  }

  getNodesList(type) {
    this.setState({
      loading_txns: true
    })
    rtservices.getRametronListForType(type).then((data) => {
      let finaldata = []
      for (let i of data.data) {
        if(i.active){
          finaldata.push(i)
        }
      }
      for (let i of finaldata) {
        i.totalstake = undefined;
      }
      this.setState({
        publicnnode: finaldata,
        activeType: type,
        loading_txns: false
      })
      for (let i of this.state.publicnnode) {
        this.getStakeByAddress(i.walletaddress);
        // i.walletcount = this.getStakeByAddress(i.walletaddress).walletcount;
      }
    })
      .catch((err) => {

      });
  }



  tst() {
    this.setState({
      loading_txns: true
    })
    // rtservices.totalNodetype().then((data) => {

      let unique = ["RT Enterprise","RT Pro","RT Lite","RT Mobile"]
      // let finaluniqye = ["RT Enterprise","RT Pro","RT Lite","RT Mobile"]
     
      this.setState({
        nodetype: unique,
        activeType: unique[0]
      })
      this.getNodesList(unique[0])


    // })
    //   .catch((err) => {

    //   });
  }


  getStakeByAddress(addresss) {
    stakeService.stakerbyAddress(addresss.toLowerCase()).then((data) => {
      for (let i of this.state.publicnnode) {
        if (i.walletaddress == addresss) {
          i.walletcount = data.data.response2[0].holder
          i.totalstake = (formatNumber(Number(data.data.response1[0].totalstake) / 1000000000000000000))
          this.setState({ showDetails: false });
          break;
        }
      }
    })
      .catch((err) => {

      });
  }

  back() {
    this.setState({ showDetails: false });
  }

  changetext(txn) {
    for (let i of this.state.publicnnode) {
      if (txn._id == i._id) {
        i.CopyNodeSummary = 'Copied!'
        this.setState({
          showDetails: false
        })

      }
      else {
        i.CopyNodeSummary = 'Copy Node Summary'
      }

    }
  }

  render() {
    const { t } = this.props;
    const { publicnnode, loading_txns, nodetype, activeType } = this.state;

    return (
      <>
        {this.state.loading ? (
          <LoadingPanel />
        ) : (
          <div className=" node-status newcideo">
            <div className=" public-node">
              {/* <div className="title pub2"><p><img src="../images/Group 4235.png" />{t(`PUBLIC NODE SUMMARY`)}

              </p></div> */}
              <div className="">

                <div className="blk9 ">
                  <div className="no-po">
                    {/* <h3>{t(`PUBLIC NODE POOL`)} */}
                      {/* <button className="btn custom-btn hom-ref" title="Refresh" onClick={() => this.tst()} ><img src="/images/Layer 2.svg" alt="" /></button> */}
                    {/* </h3> */}
                  </div>

                  <div className="nodetypws top-60">

                    {_.map(nodetype, (txn, index) => {
                      return (
                        <div className={activeType === txn ? 'nodetypwschild active' : 'nodetypwschild'} onClick={() => { this.getNodesList(txn) }} >
                          {txn}
                        </div>
                      )
                    })
                    }
                  </div>
                  <div className="csde3">
                    <div className="table-responsive">
                      {loading_txns ?
                        <LoadingPanel />
                        :
                        <table className="data block-table">
                          <thead>
                            <tr>
                              <th className="remnode"><p>{t(`Address`)}</p></th>
                              <th className="members"><p>{t(`TOTAL_STAKED`)}</p></th>
                              <th className="uptime nonmobile"><p>{t(`NODE_TYPE`)}</p></th>
                              <th className="uptime"><p>{t(`Total Wallets`)}</p></th>
                              <th className="uptime"><p>{t(`OWNER`)}</p></th>
                            </tr>
                          </thead>
                          <tbody>
                            {_.map(publicnnode, (txn, index) => {
                              if (!txn.CopyNodeSummary) {
                                txn.CopyNodeSummary = 'Copy Node Summary'
                              }
                              if (!txn.walletcount) {
                                txn.walletcount = 'Loading...'
                              }
                              if (!txn.totalstake) {
                                txn.totalstake = 'Loading...'
                              }

                              return (
                                <tr>
                                  <td className="remnode laijdy6">
                                    <span className="nodssyn">
                                      <span className="copyNodeSummary">{txn.CopyNodeSummary}</span>
                                      <img src="../images/new-design/Copy.svg" className="copyw" onClick={() => { navigator.clipboard.writeText(txn.nodesummery); this.changetext(txn) }} />

                                    </span>
                                    <p className="wlladd">{txn.walletaddress}</p>
                                    <Link to={`/account/${txn.walletaddress}?tx=transfer`} className="mored">view</Link>
                                  </td>
                                  <td className="members">{txn.totalstake}</td>
                                  <td className="members nonmobile">{txn.type}</td>
                                  <td className="members">{txn.walletcount}</td>
                                  <td className="uptime">{txn.owner}</td>
                                </tr>
                              );
                            }
                            )}

                          </tbody>
                        </table>
                      }
                    </div>
                  </div>
                  {/* <Pagination
                    size={'lg'}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={this.handlePageChange}
                    disabled={loading_txns} /> */}
                </div>
              </div>
            </div>




          </div>
        )}
      </>
    );
  }
}

export default withTranslation()(NodeStatus);
